<div class="jumbotron vertical-center">
  <div class="container border">
    <img src="./assets/img/Panin-Daichi-det.png" alt="Panin-Daichi-Life" width="225" style="margin: 10px;">
    <div class="card">
      <div class="card-header">Permintaan Welcoming Call, silahkan lengkapi kolom di bawah ini</div>
      <div class="card-body">
        <div class="mb-3 row">
          <label for="staticNoSpaj" class="col-sm-3 col-form-label">Nomor Polis</label>
          <div class="col-sm-9">
            <input type="number" class="form-control form-control-sm" id="noSpaj" value="" [(ngModel)]="nomorPolis"
              readonly>
          </div>
        </div>
        <div class="mb-3 row">
          <label for="inputNama" style="text-transform:capitalize !important" class="col-sm-3 col-form-label">Welcome
            Call Status</label>
          <div class="col-sm-9">
            <input type="text" class="form-control form-control-sm" id="namePPPH" value="" [(ngModel)]="callStatus"
              readonly>
          </div>
        </div>
        <div class="mb-3 row">
          <b>Bersedia dihubungi kembali (Welcome Call) pada:</b>
        </div>
        <form (ngSubmit)="submitAppointment()" [formGroup]="wCall">

          <div class="mb-3 row">
            <label class="col-sm-3 col-form-label">Tanggal</label>
            <div class="col-sm-9">
              <mat-form-field class="example-full-width" appearance="fill">
                <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker" [max]="maxDate"
                  [min]="minDate" formControlName="date" (dateChange)="EndDateChange($event)" disable>
                <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                <mat-datepicker #picker [disabled]="!isEdit"></mat-datepicker>
                <mat-hint align="end">format DD/MM/YYYY</mat-hint>
                <mat-error *ngIf="this.wCall.controls['date'].hasError('matDatepickerMin')">tidak boleh dihari sebelumnya</mat-error>
                <mat-error *ngIf="this.wCall.controls['date'].hasError('matDatepickerMax')">tidak boleh melebihi masa period</mat-error>
                <mat-error *ngIf="this.wCall.controls['date'].hasError('matDatepickerFilter')">tidak boleh melakukan appointment pada hari ini</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-3 col-form-label">Jam</label>
            <div class="col-sm-9">
              <mat-form-field class="example-full-width" appearance="fill">
                <mat-select formControlName="hour" [disabled]="!wCall.controls['date'].valid">
                  <mat-option *ngFor="let hour of hours" [value]="hour.value" [disabled]="hour.disabled">
                    {{hour.viewValue}}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>
          <div class="mb-3 row">
            <label class="col-sm-3 col-form-label">Keterangan Nasabah</label>
            <div class="col-sm-9">
              <mat-form-field class="example-full-width" hintLabel="Max 250 characters" appearance="fill">
                <textarea matInput cdkTextareaAutosize #autosize="cdkTextareaAutosize" cdkAutosizeMinRows="1" maxlength="250"
                  formControlName="desc" cdkAutosizeMaxRows="5"></textarea>
                  <mat-hint align="end">{{this.wCall.get('desc')!.value.length || 0}}/250</mat-hint>
              </mat-form-field>
            </div>
          </div>
          <div class="d-grid" *ngIf="isEdit">
            <button type="submit" class="btn btn-primary" id="btnLanjut">{{
              'home.btnLanjut' | translate }}</button>
          </div>
        </form>

      </div>

    </div>
  </div>
</div>