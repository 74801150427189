import { Component, OnInit, NgZone } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute,Router,NavigationStart  } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { SPAJDTO } from 'src/app/interfaces/dto/spaj-dto';
import { CardTokenRq } from 'src/app/interfaces/request/midtrans/card-token-rq';
import { PaymentService } from 'src/app/services/payment/payment.service';
import { CreditCardValidator } from 'src/app/validators/credit-card-validator';
import { NumberValidator } from 'src/app/validators/number-validator';
import Swal from 'sweetalert2';
import { AuthorizeCcRq } from 'src/app/interfaces/request/authorize-cc/authorize-cc-rq';
import { AuthenticationService } from 'src/app/services/utill/authentication.service';
import { AuthorizeCcRs } from 'src/app/interfaces/response/authorize-cc/authorize-cc-rs';
import { AuthorizeCcService } from 'src/app/services/authorize-cc/authorize-cc.service';
import { environment } from 'src/environments/environment';
import { CardTokenRs } from 'src/app/interfaces/response/midtrans/card-token-rs';
import { ChargePaymentRq } from 'src/app/interfaces/request/charge-payment-rq';
import { PaymentTrxRs } from 'src/app/interfaces/response/midtrans/payment-trx-rs';
import { CapturePaymentRq } from 'src/app/interfaces/request/capture-payment-rq';
import { CancelPaymentRq } from 'src/app/interfaces/request/cancel-payment-rq';
import { ListBniPaninService } from 'src/app/services/listBniPanin/list-bni-panin.service';
import { ListBniPaninRq } from 'src/app/interfaces/request/listBniPanin/list-bni-panin-rq';
import { noop } from 'rxjs';

declare function getCardToken(cardData: any, result: any):any;
declare function reqOpen3DS(redirect_url: string, result: any):any;
@Component({
  selector: 'app-auto-debit',
  templateUrl: './auto-debit.component.html',
  styleUrls: ['./auto-debit.component.scss']
})
export class AutoDebitComponent implements OnInit {
  onlineOffline: boolean = false;
  paymentForm: FormGroup;
  param:string = '';
  names:string ='';
  syaflag:string= '';
  cardNoTemp: string='';
  mmTemp: string='';
  yyTemp: string='';
  cvvTemp: string='';
  contentCheck: boolean = false;
  
  premiTmp: string='';
  topUpTmp: string='';
  totalTmp: string='';
  channelTmp: string='';

  authorizeCcRq: AuthorizeCcRq ={
    spajNo :'',
    name :'',
    nik :'',
    dob:''
  };

  authorizeCcRs: AuthorizeCcRs[]= [];
  load: boolean = false;

  noCardBniPanin: ListBniPaninRq = {
    noCreditCard : '',
    channel : ''
  };

  typeCard: string = '';

  element: any = {
    name: "",
    firstPremi: "",
    renewalPremi: "",
    email: "",
    flagSharia: ""
  }

  constructor(private translateSvc: TranslateService, private paymentSvc: PaymentService, private route: ActivatedRoute,private router:Router,private authenticationSvc: AuthenticationService,private authorizeSvc: AuthorizeCcService, private _ngZone: NgZone, private listBniPaninService: ListBniPaninService) {
    this.paymentForm = new FormGroup({
      spajNo: new FormControl("", [Validators.required]),
      policyName: new FormControl("", [Validators.required]),
      currency: new FormControl("", [Validators.required]),
      frequency: new FormControl("", [Validators.required]),
      premi: new FormControl("", [Validators.required]),
      topUp: new FormControl("", [Validators.required]),
      total: new FormControl("", [Validators.required]),
      firstMethod: new FormControl("", [Validators.required]),
      advanceMethod: new FormControl("", [Validators.required]),
      cardNo: new FormControl("", [Validators.required, CreditCardValidator.isValid]),
      month: new FormControl("", [Validators.required, NumberValidator.isValid]),
      year: new FormControl("", [Validators.required, NumberValidator.isValid]),
      cvv: new FormControl("", [Validators.required, NumberValidator.isValid]),
      agreement: new FormControl(false, [Validators.nullValidator]),
      email: new FormControl()
    });
    /* //for send param with object
    this.route.params.subscribe((params) => {
      if (params) {
        this.authorizeCcRq.spajNo = params.spajNo?.trim();
        this.authorizeCcRq.namaPH = params.namaPH?.trim();
        this.authorizeCcRq.nik = params.nik?.trim();
        this.authorizeCcRq.dob = params.dob?.trim();
      }
    });
    */
    //for dynamic send object only 1 hit get available data
    //console.log('tes :'+this.router.getCurrentNavigation()?.extras.state);
    //get dynamic text
    this.param='';
    this.names='';
    this.syaflag='';
    this.premiTmp='';
    this.topUpTmp='';
    this.totalTmp='';
    this.channelTmp='';
  }

  ngOnInit(): void {
    this.param=this.authenticationSvc.getUser();
    this.names=this.authenticationSvc.getNamesText();
    this.syaflag=this.authenticationSvc.getSyaFlagText();
    //for dynamic send object only 1 hit get available data
    //console.log(history.state);
    if(history.state.spajNo!=null && history.state.name!=null && history.state.nik!=null && history.state.dob!=null){
      this.authorizeCcRq.spajNo = history.state.spajNo;
      this.authorizeCcRq.name = history.state.name;
      this.authorizeCcRq.nik = history.state.nik;
      this.authorizeCcRq.dob = history.state.dob;
      //check connection
      if(this.getConnection()){
        //post data for get microsite data
        this.authorizeSvc.sendAuthorizeCcAPI(this.authorizeCcRq).subscribe(
          (responseData) => {
            if(responseData.length > 0){
              console.log('sendAuthorizeCcAPI : available data');
              this.authorizeCcRs = responseData;
              this.authorizeSvc.sendAuthorizeCc(this.authorizeCcRq).subscribe(
                (responseDatas) => {
                  if(responseDatas.length > 0){
                    console.log('sendAuthorizeCc : available data');
                    this.authorizeCcRs = responseDatas;
                    this.paymentForm.controls.spajNo.setValue(this.authorizeCcRs[0]?.spajNo);
                    this.paymentForm.controls.policyName.setValue(this.authorizeCcRs[0]?.name);
                    this.paymentForm.controls.currency.setValue(this.authorizeCcRs[0]?.mataUang);
                    this.paymentForm.controls.email.setValue(this.authorizeCcRs[0]?.email);
                    this.sendNewDataCharge(this.authorizeCcRs, this.syaflag);
                    //console.log('channel :'+this.channelTmp);
                  }else{
                    console.log('sendAuthorizeCc :not available data');
                    this.translateSvc.get("payment.failDataAutoDebitCC").subscribe(word => {
                      Swal.fire({
                        icon: 'error',
                        text: word,
                        confirmButtonColor: '#BB2D3B'
                      }).then((result) =>{
                        if (result.isConfirmed) {
                          //this.param =this.authenticationSvc.getUser();
                          this.router.navigate([
                            'authorizecc',
                            {
                              ota: this.param
                            },
                          ]);
                          console.log('BACK TO AUTHORIZE CC');
                        }  
                        
                      });
                    })
                  }
                  
                },
                (error) => {
                  console.log(error);
                  if (error.status !== 200) {
                    //this.auth.logoutUser();
                    this.translateSvc.get("payment.failDataAutoDebitCC").subscribe(word => {
                      Swal.fire({
                        icon: 'error',
                        text: word,
                        confirmButtonColor: '#BB2D3B'
                      }).then(() =>{
                        //this.param =this.authenticationSvc.getUser();
                        this.router.navigate([
                          'authorizecc',
                          {
                            ota: this.param
                          },
                        ]);
                        console.log('BACK TO AUTHORIZE CC');
                      });
                    })
                  }
              });
              this.paymentForm.controls.frequency.setValue(this.authorizeCcRs[0]?.freq);
              this.paymentForm.controls.premi.setValue(this.toCommas(this.authorizeCcRs[0]?.regularPremi));
              this.premiTmp = this.authorizeCcRs[0]?.regularPremi;
              this.paymentForm.controls.topUp.setValue(this.toCommas(this.authorizeCcRs[0]?.regularTopUp));
              this.topUpTmp = this.authorizeCcRs[0]?.regularTopUp;
              this.paymentForm.controls.total.setValue(this.toCommas(this.authorizeCcRs[0]?.totalPremi));
              this.totalTmp = this.authorizeCcRs[0]?.totalPremi;
              this.paymentForm.controls.firstMethod.setValue(this.authorizeCcRs[0]?.premiPertama);
              this.paymentForm.controls.advanceMethod.setValue(this.authorizeCcRs[0]?.premiLanjutan);
              this.channelTmp = this.authorizeCcRs[0]?.channel;
              this.sendNewDataCharge(this.authorizeCcRs, this.syaflag);
              console.log('channel :'+this.channelTmp);
            }else{
              console.log('sendAuthorizeCcAPI :not available data');
              this.authorizeSvc.sendAuthorizeCc(this.authorizeCcRq).subscribe(
                (responseDatas) => {
                  if(responseDatas.length > 0){
                    console.log('sendAuthorizeCc : available data');
                    this.authorizeCcRs = responseDatas;
                    this.paymentForm.controls.spajNo.setValue(this.authorizeCcRs[0]?.spajNo);
                    this.paymentForm.controls.policyName.setValue(this.authorizeCcRs[0]?.name);
                    this.paymentForm.controls.currency.setValue(this.authorizeCcRs[0]?.mataUang);
                    this.paymentForm.controls.frequency.setValue(this.authorizeCcRs[0]?.freq);
                    this.paymentForm.controls.premi.setValue(this.toCommas(this.authorizeCcRs[0]?.regularPremi));
                    this.premiTmp = this.authorizeCcRs[0]?.regularPremi;
                    this.paymentForm.controls.topUp.setValue(this.toCommas(this.authorizeCcRs[0]?.regularTopUp));
                    this.topUpTmp = this.authorizeCcRs[0]?.regularTopUp;
                    this.paymentForm.controls.total.setValue(this.toCommas(this.authorizeCcRs[0]?.totalPremi));
                    this.totalTmp = this.authorizeCcRs[0]?.totalPremi;
                    this.paymentForm.controls.firstMethod.setValue(this.authorizeCcRs[0]?.premiPertama);
                    this.paymentForm.controls.advanceMethod.setValue(this.authorizeCcRs[0]?.premiLanjutan);
                    this.channelTmp = this.authorizeCcRs[0]?.channel;
                    this.paymentForm.controls.email.setValue(this.authorizeCcRs[0]?.email);
                    this.sendNewDataCharge(this.authorizeCcRs, this.syaflag);
                    console.log('channel :'+this.channelTmp);
                  }else{
                    console.log('sendAuthorizeCc :not available data');
                    this.translateSvc.get("payment.failDataAutoDebitCC").subscribe(word => {
                      Swal.fire({
                        icon: 'error',
                        text: word,
                        confirmButtonColor: '#BB2D3B'
                      }).then((result) =>{
                        if (result.isConfirmed) {
                          //this.param =this.authenticationSvc.getUser();
                          this.router.navigate([
                            'authorizecc',
                            {
                              ota: this.param
                            },
                          ]);
                          console.log('BACK TO AUTHORIZE CC');
                        }  
                        
                      });
                    })
                  }
                  
                },
                (error) => {
                  console.log(error);
                  if (error.status !== 200) {
                    //this.auth.logoutUser();
                    this.translateSvc.get("payment.failDataAutoDebitCC").subscribe(word => {
                      Swal.fire({
                        icon: 'error',
                        text: word,
                        confirmButtonColor: '#BB2D3B'
                      }).then(() =>{
                        //this.param =this.authenticationSvc.getUser();
                        this.router.navigate([
                          'authorizecc',
                          {
                            ota: this.param
                          },
                        ]);
                        console.log('BACK TO AUTHORIZE CC');
                      });
                    })
                  }
              });
            }
            
          },
          (error) => {
            console.log(error);
            console.log('sendAuthorizeCcAPI :not available data');
            if (error.status !== 200) {
              this.authorizeSvc.sendAuthorizeCc(this.authorizeCcRq).subscribe(
                (responseDatas) => {
                  if(responseDatas.length > 0){
                    console.log('sendAuthorizeCc :available data');
                    this.authorizeCcRs = responseDatas;
                    this.paymentForm.controls.spajNo.setValue(this.authorizeCcRs[0]?.spajNo);
                    this.paymentForm.controls.policyName.setValue(this.authorizeCcRs[0]?.name);
                    this.paymentForm.controls.currency.setValue(this.authorizeCcRs[0]?.mataUang);
                    this.paymentForm.controls.frequency.setValue(this.authorizeCcRs[0]?.freq);
                    this.paymentForm.controls.premi.setValue(this.toCommas(this.authorizeCcRs[0]?.regularPremi));
                    this.premiTmp = this.authorizeCcRs[0]?.regularPremi;
                    this.paymentForm.controls.topUp.setValue(this.toCommas(this.authorizeCcRs[0]?.regularTopUp));
                    this.topUpTmp = this.authorizeCcRs[0]?.regularTopUp;
                    this.paymentForm.controls.total.setValue(this.toCommas(this.authorizeCcRs[0]?.totalPremi));
                    this.totalTmp = this.authorizeCcRs[0]?.totalPremi;
                    this.paymentForm.controls.firstMethod.setValue(this.authorizeCcRs[0]?.premiPertama);
                    this.paymentForm.controls.advanceMethod.setValue(this.authorizeCcRs[0]?.premiLanjutan);
                    this.channelTmp = this.authorizeCcRs[0]?.channel;
                    this.paymentForm.controls.email.setValue(this.authorizeCcRs[0]?.email);
                    this.sendNewDataCharge(this.authorizeCcRs, this.syaflag);
                    console.log('channel :'+this.channelTmp);
                  }else{
                    console.log('sendAuthorizeCc :not available data');
                    this.translateSvc.get("payment.failDataAutoDebitCC").subscribe(word => {
                      Swal.fire({
                        icon: 'error',
                        text: word,
                        confirmButtonColor: '#BB2D3B'
                      }).then((result) =>{
                        if (result.isConfirmed) {
                          //this.param =this.authenticationSvc.getUser();
                          this.router.navigate([
                            'authorizecc',
                            {
                              ota: this.param
                            },
                          ]);
                          console.log('BACK TO AUTHORIZE CC');
                        }  
                        
                      });
                    })
                  }
                  
                },
                (error) => {
                  console.log(error);
                  if (error.status !== 200) {
                    //this.auth.logoutUser();
                    this.translateSvc.get("payment.failDataAutoDebitCC").subscribe(word => {
                      Swal.fire({
                        icon: 'error',
                        text: word,
                        confirmButtonColor: '#BB2D3B'
                      }).then(() =>{
                        //this.param =this.authenticationSvc.getUser();
                        this.router.navigate([
                          'authorizecc',
                          {
                            ota: this.param
                          },
                        ]);
                        console.log('BACK TO AUTHORIZE CC');
                      });
                    })
                  }
              });
            }
        });
      } else{
        this.translateSvc.get("connections.fail").subscribe(word => {
          Swal.fire({
            icon: 'info',
            title:'<p>Koneksi Internet Terputus !!!</p>',
            text: word,
            confirmButtonColor: '#0B5ED7',
            allowOutsideClick:false
          }).then(() =>{
            //this.param =this.authenticationSvc.getUser();
            this.router.navigate([
              'authorizecc',
              {
                ota: this.param
              },
            ]);
            console.log('BACK TO AUTHORIZE CC');
          });
        })
      }   
    }else{
      //this.param =this.authenticationSvc.getUser();
      this.router.navigate([
        'authorizecc',
        {
          ota: this.param
        },
      ]);
      console.log('BACK TO AUTHORIZE CC');
    }
    
    // const spajNum:string |null = this.route.snapshot.queryParamMap.get('spajNum');
    // if(spajNum){
    //   this.paymentSvc.getSPAJDetail(spajNum);
    // }
  }

  toggleEditable(event:any) {
    if ( event.target.checked ) {
        this.contentCheck = true;
   }else{
        this.contentCheck = false;
   }
}

  cancel(){
    //this.param =this.authenticationSvc.getUser();
      this.router.navigate([
        'authorizecc',
        {
          ota: this.param
        },
      ]);
      console.log('BACK TO AUTHORIZE CC');
  }

  sendNewDataCharge(authorizeCcRs: AuthorizeCcRs[], flagSharia: String){
    this.element.name = String(this.paymentForm.value['policyName']);
    
    console.log('name baru :'+this.element.name);
    console.log('name lama :'+this.authorizeCcRs[0]?.name);
    
    if(String(this.paymentForm.value['firstMethod']).toLowerCase() == "transfer"){
      this.element.firstPremi = "T";
    }else if(String(this.paymentForm.value['firstMethod']).toLowerCase() == "kartu kredit"){
      this.element.firstPremi = "C";
    }else if(String(this.paymentForm.value['firstMethod']).toLowerCase() == "kartu kredit online"){
      this.element.firstPremi = "O";
    }else if(String(this.paymentForm.value['firstMethod']).toLowerCase() == "autodebet"){
      this.element.firstPremi = "A";
    }
    console.log('firstPremi :'+this.element.firstPremi);
    if(String(this.paymentForm.value['advanceMethod']).toLowerCase() == "transfer"){
      this.element.renewalPremi = "T";
    }else if(String(this.paymentForm.value['advanceMethod']).toLowerCase() == "kartu kredit"){
      this.element.renewalPremi = "C";
    }else if(String(this.paymentForm.value['advanceMethod']).toLowerCase() == "kartu kredit online"){
      this.element.renewalPremi = "O";
    }else if(String(this.paymentForm.value['advanceMethod']).toLowerCase() == "autodebet"){
      this.element.renewalPremi = "A";
    }
    console.log('renewalPremi :'+this.element.renewalPremi);
    this.element.email = String(this.paymentForm.value['email']);
    console.log('email baru :'+this.element.email);
    console.log('email lama :'+this.authorizeCcRs[0]?.email);
    this.element.flagSharia = flagSharia.toString();
    console.log('flagSharia :'+this.element.flagSharia);
  }

  test(){
    this.noCardBniPanin.noCreditCard = '19463278967896';

    this.listBniPaninService.checkListBniPanin(this.noCardBniPanin).subscribe(
      (responseData) => {
        if(responseData.resultString !== ''){
          console.log('channel :'+responseData.resultString);
        }else{
          this.translateSvc.get("payment.failListBbni").subscribe(word => {
            Swal.fire({
              icon: 'error',
              text: word,
              confirmButtonColor: '#BB2D3B'
            }).then((result) =>{
              if (result.isConfirmed) {
                //this.param =this.authenticationSvc.getUser();
                this.router.navigate([
                  'authorizecc',
                  {
                    ota: this.param
                  },
                ]);
                console.log('BACK TO AUTHORIZE CC');
              }  
              
            });
          })
        }
        
      },
      (error) => {
        console.log(error);
        if (error.status !== 200) {
          //this.auth.logoutUser();
          this.translateSvc.get("payment.failListBbni").subscribe(word => {
            Swal.fire({
              icon: 'error',
              text: word,
              confirmButtonColor: '#BB2D3B'
            }).then(() =>{
              //this.param =this.authenticationSvc.getUser();
              this.router.navigate([
                'authorizecc',
                {
                  ota: this.param
                },
              ]);
              console.log('BACK TO AUTHORIZE CC');
            });
          })
        }
    });
  }

  checkConnection(){
    //CHECK KONEKSI
    this.onlineOffline= navigator.onLine;
    window.addEventListener('online', () => {this.onlineOffline = true});
    window.addEventListener('offline', () => {this.onlineOffline = false});
  }

  getConnection(): boolean{
    //CHECK KONEKSI
    this.onlineOffline= navigator.onLine;
    window.addEventListener('online', () => {this.onlineOffline = true});
    window.addEventListener('offline', () => {this.onlineOffline = false});
    return this.onlineOffline;
  }

  show(){
    this.load=true;
  }

  hide(){
    // window.setTimeout(() => {
    //   this.load = false,
    // }, 500);
    setTimeout(() => {
      this.load = false;
    }, 1000);
    //this.load=false;
  }

  showed(){
    const scriptTags = document.createElement('script');
    scriptTags.type = "text/javascript";
    document.body.appendChild(scriptTags);
    scriptTags.onload = (script) => {
      this.load = true;
      document.body.removeChild(scriptTags);
    };
  }

  hided(){
    const scriptTags = document.createElement('script');
    scriptTags.type = "text/javascript";
    document.body.appendChild(scriptTags);
    scriptTags.onload = (script) => {
      this.load = false;
      document.body.removeChild(scriptTags);
    };
  }

  captureShow(){
    var konfir = <HTMLInputElement>document.getElementById('konfirmasi');
    var batal = <HTMLInputElement>document.getElementById('batal');
    konfir.disabled = true;
    batal.disabled = true;
  }

  captureHide(){
    var konfir = <HTMLInputElement>document.getElementById('konfirmasi');
    var batal = <HTMLInputElement>document.getElementById('batal');
    konfir.disabled = false;
    batal.disabled = false;
  }

  removejscssfile(filename: string, filetype: string){
    var targetelement=(filetype=="js")? "script" : (filetype=="css")? "link" : "none" //determine element type to create nodelist from
    var targetattr=(filetype=="js")? "src" : (filetype=="css")? "href" : "none" //determine corresponding attribute to test for
    var allsuspects=document.getElementsByTagName(targetelement)
    for (var i=allsuspects.length; i>=0; i--){ //search backwards within nodelist for matching elements to remove
    if (allsuspects[i] && allsuspects[i].getAttribute(targetattr)!=null && allsuspects[i]?.getAttribute(targetattr)?.indexOf(filename)!=-1)
        allsuspects[i]?.parentNode?.removeChild(allsuspects[i]) //remove element by calling parentNode.removeChild()
    }
  }
 
  removeAllScript(){
    var tags = document.getElementsByTagName('script');
      for (var i = tags.length; i >= 0; i--){ //search backwards within nodelist for matching elements to remove
       if (tags[i] && tags[i].getAttribute('src') != null)
        tags[i]?.parentNode?.removeChild(tags[i]); //remove element by calling parentNode.removeChild()
      }
      //this.removejscssfile('midtrans.js','js');
  }

  sendChargePayment(authorizeCcRq :AuthorizeCcRq, element: any){
    this.show();
    const spaj: SPAJDTO = {
      spajNum: String(this.paymentForm.value['spajNo']).toLowerCase(),
      //currency: this.paymentForm.value['currency'],
      firstPayMethod: String(this.paymentForm.value['firstMethod']).toLowerCase(),
      frequency: String(this.paymentForm.value['frequency']).toLowerCase(),
      holderName: String(this.paymentForm.value['policyName']).toLowerCase(),
      nextPayMethod: String(this.paymentForm.value['advanceMethod']).toLowerCase(),
      premium: Number(this.premiTmp),
      topUp: Number(this.topUpTmp),
      totalAmt: Number(this.totalTmp)
    };

    const credit: CardTokenRq = {
      card_cvv: this.paymentForm.value['cvv'],
      card_exp_month: this.paymentForm.value['month'],
      card_exp_year: '20'+this.paymentForm.value['year'],
      card_number: this.paymentForm.value['cardNo']
    };
    //check connection
    if(this.getConnection()){
      this.noCardBniPanin.noCreditCard = String(this.paymentForm.value['cardNo']).trim();
      this.noCardBniPanin.channel = this.channelTmp;
      //if(this.channelTmp == 'A'){
        this.listBniPaninService.checkListBniPanin(this.noCardBniPanin).subscribe(
          (responseData) => {
            if(responseData.resultString !== ''){
              if(responseData.resultString !== 'BNICC'){
                if(this.noCardBniPanin.noCreditCard.substr(0,4) !== '1800'){
                  if(this.noCardBniPanin.noCreditCard.substr(0,1) === '1'){
                    this.hide();
                    this.translateSvc.get("payment.failCvv").subscribe(word => {
                      Swal.fire({
                        icon: 'error',
                        text: word,
                        confirmButtonColor: '#BB2D3B'
                      });
                    })
                  }else{
                    console.log('cardBniType :'+responseData.resultString);
                    this.typeCard = responseData.resultString;
                    this.createPayToken(spaj, credit, authorizeCcRq, this.typeCard, element);
                  }
                }else{
                  console.log('cardBniType :'+responseData.resultString);
                  this.typeCard = responseData.resultString;
                  this.createPayToken(spaj, credit, authorizeCcRq, this.typeCard, element);
                }
              }else{
                console.log('cardBniType :'+responseData.resultString);
                this.typeCard = responseData.resultString;
                this.createPayToken(spaj, credit, authorizeCcRq, this.typeCard, element);
              }
            }else{
              this.hide();
              this.translateSvc.get("payment.failListBbni").subscribe(word => {
                Swal.fire({
                  icon: 'error',
                  text: word,
                  confirmButtonColor: '#BB2D3B'
                }).then((result) =>{
                  if (result.isConfirmed) {
                    //this.param =this.authenticationSvc.getUser();
                    this.router.navigate([
                      'authorizecc',
                      {
                        ota: this.param
                      },
                    ]);
                    console.log('BACK TO AUTHORIZE CC');
                  }  
                  
                });
              })
            }
            
          },
          (error) => {
            console.log(error);
            this.hide();
            if (error.status !== 200) {
              //this.auth.logoutUser();
              this.translateSvc.get("payment.failListBbni").subscribe(word => {
                Swal.fire({
                  icon: 'error',
                  text: word,
                  confirmButtonColor: '#BB2D3B'
                }).then(() =>{
                  //this.param =this.authenticationSvc.getUser();
                  this.router.navigate([
                    'authorizecc',
                    {
                      ota: this.param
                    },
                  ]);
                  console.log('BACK TO AUTHORIZE CC');
                });
              })
            }
        });
      /*}else{
        //channel L
        this.listBniPaninService.checkListBniPanin(this.noCardBniPanin).subscribe(
          (responseData) => {
            if(responseData.result !== ''){
              if(responseData.result === 'OTHCC'){
                console.log('cardPaninType :'+responseData.result);
                this.typeCard = responseData.result;
                this.createPayToken(spaj, credit, authorizeCcRq, this.typeCard, element);
              }else{
                this.hide();
                this.translateSvc.get("payment.failListPanin").subscribe(word => {
                  Swal.fire({
                    icon: 'error',
                    text: word,
                    confirmButtonColor: '#BB2D3B'
                  });
                })
              }
            }else{
              this.hide();
              this.translateSvc.get("payment.failListPanin").subscribe(word => {
                Swal.fire({
                  icon: 'error',
                  text: word,
                  confirmButtonColor: '#BB2D3B'
                }).then((result) =>{
                  if (result.isConfirmed) {
                    //this.param =this.authenticationSvc.getUser();
                    this.router.navigate([
                      'authorizecc',
                      {
                        ota: this.param
                      },
                    ]);
                    console.log('BACK TO AUTHORIZE CC');
                  }  
                  
                });
              })
            }
            
          },
          (error) => {
            console.log(error);
            this.hide();
            if (error.status !== 200) {
              //this.auth.logoutUser();
              this.translateSvc.get("payment.failListPanin").subscribe(word => {
                Swal.fire({
                  icon: 'error',
                  text: word,
                  confirmButtonColor: '#BB2D3B'
                }).then(() =>{
                  //this.param =this.authenticationSvc.getUser();
                  this.router.navigate([
                    'authorizecc',
                    {
                      ota: this.param
                    },
                  ]);
                  console.log('BACK TO AUTHORIZE CC');
                });
              })
            }
        });
      }
      */
    }else{
      this.hide();
      this.translateSvc.get("connections.fail").subscribe(word => {
        Swal.fire({
          icon: 'info',
          title:'<p>Koneksi Internet Terputus !!!</p>',
          text: word,
          confirmButtonColor: '#0B5ED7',
          allowOutsideClick:false
        }).then(() =>{
          //this.param =this.authenticationSvc.getUser();
          this.router.navigate([
            'authorizecc',
            {
              ota: this.param
            },
          ]);
          console.log('BACK TO AUTHORIZE CC');
        });
      })
    }
  }

  toCommas(value:string) {
    return value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }

  chargePayment() {
    if (this.paymentForm.valid && this.contentCheck == true) {
      this.cardNoTemp = this.paymentForm.value.cardNo;
      this.mmTemp = this.paymentForm.value.month;
      this.yyTemp = this.paymentForm.value.year;
      this.cvvTemp = this.paymentForm.value.cvv;

      let regexpNumber = new RegExp('^0[1-9]|1[0-2]$');

      if(this.cardNoTemp.length == 16 && this.mmTemp.length == 2 && regexpNumber.test(this.mmTemp) && this.yyTemp.length == 2 && this.cvvTemp.length == 3){
        // if(this.cardNoTemp.substr(0,4) !== '1800'){
        //   if(this.cardNoTemp.substr(0,1) === '1'){
        //     this.translateSvc.get("payment.failCvv").subscribe(word => {
        //       Swal.fire({
        //         icon: 'error',
        //         text: word,
        //         confirmButtonColor: '#BB2D3B'
        //       });
        //     })
        //   }else{
        //     this.sendChargePayment(this.authorizeCcRq);
        //   }
        // }else{
        //   this.sendChargePayment(this.authorizeCcRq);
        // }
        this.sendChargePayment(this.authorizeCcRq,this.element);
      }else if(this.cardNoTemp.length < 16){
        this.translateSvc.get("payment.failSizeCard").subscribe(word => {
          Swal.fire({
            icon: 'error',
            text: word,
            confirmButtonColor: '#BB2D3B'
          });
        })
      }else if(this.mmTemp.length < 2 || !regexpNumber.test(this.mmTemp)){
        this.translateSvc.get("payment.failSizeMM").subscribe(word => {
          Swal.fire({
            icon: 'error',
            text: word,
            confirmButtonColor: '#BB2D3B'
          });
        })
      }else if(this.yyTemp.length < 2){
        this.translateSvc.get("payment.failSizeYY").subscribe(word => {
          Swal.fire({
            icon: 'error',
            text: word,
            confirmButtonColor: '#BB2D3B'
          });
        })
      }else if(this.cvvTemp.length < 3){
        this.translateSvc.get("payment.failSizeCvv").subscribe(word => {
          Swal.fire({
            icon: 'error',
            text: word,
            confirmButtonColor: '#BB2D3B'
          });
        })
      }
      
    } else {
      this.translateSvc.get("payment.failForm").subscribe(word => {
        Swal.fire({
          icon: 'error',
          text: word,
          confirmButtonColor: '#BB2D3B'
        });
      })
    }
  }

  createPayToken(spaj: SPAJDTO, creditData: CardTokenRq, authorizeCcRq :AuthorizeCcRq, typeCard: string, element: any) {
    const scriptTag = document.createElement('script');
    scriptTag.src = environment.midtrans.scriptUri;
    scriptTag.id = "midtrans-script";
    scriptTag.type = "text/javascript";
    scriptTag.setAttribute('data-client-key', environment.midtrans.clientKey);
    scriptTag.setAttribute('data-environment', environment.midtrans.env);
    document.body.appendChild(scriptTag);
    this.hide();
    scriptTag.onload = (script) => {
      console.log(script + ' loaded!');
      getCardToken(creditData, (result: any) => this.chargePaymentMidtrans(result, spaj, authorizeCcRq, typeCard, element));
      document.body.removeChild(scriptTag);
      this.removeAllScript();
    };
    
  }

  chargePaymentMidtrans(result: CardTokenRs, spaj: SPAJDTO, authorizeCcRq :AuthorizeCcRq, typeCard: string, element: any) {
    //this.hide();
    if (result.status_code === "200") {
      const req: ChargePaymentRq = {
        method: "credit_card",
        tokenId: result.token_id,
        totalAmt: spaj.totalAmt,
        spaj: spaj,
        usrRq: 'microsite',
        bnkChargeCode: typeCard,
        name: element.name,
        firstPremi: element.firstPremi,
        renewalPremi: element.renewalPremi,
        email:element.email,
        flagSharia:element.flagSharia
      }
      
      this.paymentSvc.chargePaymentNew(req).subscribe(rs => {
        if (rs.statusCode === "201") {
          reqOpen3DS(rs.redirectUri, (result: any) => this.capturePayment(result,spaj,authorizeCcRq,typeCard));

        } else if (rs.statusCode === "200") {
          this.translateSvc.get("payment.successTrx").subscribe(word => {
            Swal.fire({
              icon: 'success',
              text: word,
              confirmButtonColor: '#0B5ED7'
            });
          })

        } else {
          this.translateSvc.get("payment.failedTrx").subscribe(word => {
            Swal.fire({
              icon: 'error',
              text: word + "" + rs.message,
              confirmButtonColor: '#BB2D3B'
            });
          })
        }
      },(error) => {
        console.log(error);
        if (error.status !== 200) {
          this.translateSvc.get("payment.failedTrx").subscribe(word => {
            Swal.fire({
              icon: 'error',
              text: word + "" + error.error.message,
              confirmButtonColor: '#BB2D3B'
            });
          })
        }
      });
    
    } else {
      this.translateSvc.get("payment.failedTrx").subscribe(word => {
        Swal.fire({
          icon: 'error',
          text: word + "" + result.status_message,
          confirmButtonColor: '#BB2D3B'
        });
      })
    }
  }

  capturePayment(req: PaymentTrxRs, spaj: SPAJDTO, authorizeCcRq :AuthorizeCcRq, typeCard :string) {
    //this.load = true;
    //console.log('transaction-status :'+req.transaction_status);
    //status : capture
    //status : deny
    /*
    if(req.fraud_status === 'challenge'){
      var msg='Transaksi tidak berhasil, hubungi Bank Penerbit Kartu Kredit Anda';
      // if(req.channel_response_code == "05" && req.channel_response_message == "Do not honour"){
      //   msg='Transaksi tidak berhasil, hubungi Bank Penerbit Kartu Kredit Anda';
      // }
      const capturePaymentReq: CapturePaymentRq = {
        capture: req,
        spaj: spaj,
        usrRq: 'microsite',
        bnkChargeCode : typeCard
      };

      this.paymentSvc.capturePaymentNew(capturePaymentReq).subscribe(rs => {
        //this.load=false;
        if (rs.statusCode === "200") {
          Swal.fire({
            icon: 'error',
            text: msg,
            confirmButtonColor: '#BB2D3B',
            allowOutsideClick:false
          }).then((result) =>{
            if (result.isConfirmed) {
              localStorage.removeItem("res");  
              this._ngZone.run(() => {
                this.removeAllScript();
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigateByUrl('/payment', { state: authorizeCcRq,skipLocationChange: true });
              });
            }  
          });
        } else {
          //if(msg!=""){
            Swal.fire({
              icon: 'error',
              text: msg,
              confirmButtonColor: '#BB2D3B',
              allowOutsideClick:false
            }).then((result) =>{
              if (result.isConfirmed) {
                localStorage.removeItem("res");  
                this._ngZone.run(() => {
                  this.removeAllScript();
                  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                  this.router.onSameUrlNavigation = 'reload';
                  this.router.navigateByUrl('/payment', { state: authorizeCcRq,skipLocationChange: true });
                });            
              }  
            });
        }
      },(error) => {
        //this.load=false;
        console.log(error);
        if (error.status !== 200) {
          Swal.fire({
            icon: 'error',
            text: error.error.message,
            confirmButtonColor: '#BB2D3B',
            allowOutsideClick:false,
            //showConfirmButton:false
          }).then((result) =>{
            if (result.isConfirmed) {
              localStorage.removeItem("res");  
              this._ngZone.run(() => {
                this.removeAllScript();
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigateByUrl('/payment', { state: authorizeCcRq,skipLocationChange: true });
              });           
            }  
          });
        }
      });
    }
    else if(req.transaction_status === 'capture'){
      this.captureShow();
      const capturePaymentReq: CapturePaymentRq = {
        capture: req,
        spaj: spaj,
        usrRq: 'microsite',
        bnkChargeCode : typeCard
      };

      this.paymentSvc.capturePaymentNew(capturePaymentReq).subscribe(rs => {
        //this.load=false;
        this.captureHide();
        this.removeAllScript();
        if (rs.statusCode === "200") {
          
            Swal.fire({
              icon: 'success',
              title:'<p><b>Pemberitahuan</b></p>',
              html: 'Registrasi Kartu Kredit Online Dan Pendebetan Sudah Berhasil.' + '<br/><br/>' + 'Silahkan Tutup Halaman Ini.',
              confirmButtonColor: '#0B5ED7',
              allowOutsideClick:false,
              showConfirmButton:false
            });
          
        } else {
          Swal.fire({
            icon: 'error',
            text: rs.message,
            confirmButtonColor: '#BB2D3B',
            //allowOutsideClick:false,
            //showConfirmButton:false
          });
        }
      },(error) => {
        this.captureHide();
        this.removeAllScript();
        //this.load=false;
        console.log(error);
        if (error.status !== 200) {
          Swal.fire({
            icon: 'error',
            text: error.error.message,
            confirmButtonColor: '#BB2D3B',
            //allowOutsideClick:false,
            //showConfirmButton:false
          });
        }
      });
    }else{
      var msg='Transaksi tidak berhasil, hubungi Bank Penerbit Kartu Kredit Anda';
      // if(req.channel_response_code == "05" && req.channel_response_message == "Do not honour"){
      //   msg='Transaksi tidak berhasil, hubungi Bank Penerbit Kartu Kredit Anda';
      // }
      const cancelPaymentReq: CancelPaymentRq = {
        cancel: req,
        spaj: spaj,
        usrRq: 'microsite',
        bnkChargeCode : typeCard
      };

      this.paymentSvc.cancelPaymentNew(cancelPaymentReq).subscribe(rs => {
        //this.load=false;
        if (rs.statusCode === "200") {
          Swal.fire({
            icon: 'error',
            text: rs.message,
            confirmButtonColor: '#BB2D3B',
            allowOutsideClick:false
          }).then((result) =>{
            if (result.isConfirmed) {
              localStorage.removeItem("res");  
              this._ngZone.run(() => {
                this.removeAllScript();
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigateByUrl('/payment', { state: authorizeCcRq,skipLocationChange: true });
              });
            }  
          });
        } else {
          //if(msg!=""){
            Swal.fire({
              icon: 'error',
              text: msg,
              confirmButtonColor: '#BB2D3B',
              allowOutsideClick:false
            }).then((result) =>{
              if (result.isConfirmed) {
                localStorage.removeItem("res");  
                this._ngZone.run(() => {
                  this.removeAllScript();
                  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                  this.router.onSameUrlNavigation = 'reload';
                  this.router.navigateByUrl('/payment', { state: authorizeCcRq,skipLocationChange: true });
                });            
              }  
            });
          // }else{
          //   //console.log('messge :'+rs.message);
          //   Swal.fire({
          //     icon: 'error',
          //     text: rs.message,
          //     confirmButtonColor: '#BB2D3B',
          //     allowOutsideClick:false
          //   }).then((result) =>{
          //     if (result.isConfirmed) {
          //       localStorage.removeItem("res");  
          //       this._ngZone.run(() => {
          //         this.removeAllScript();
          //         this.router.routeReuseStrategy.shouldReuseRoute = () => false;
          //         this.router.onSameUrlNavigation = 'reload';
          //         this.router.navigateByUrl('/payment', { state: authorizeCcRq,skipLocationChange: true });
          //       });              
          //     }  
          //   });
          // }
        }
      },(error) => {
        //this.load=false;
        console.log(error);
        if (error.status !== 200) {
          Swal.fire({
            icon: 'error',
            text: error.error.message,
            confirmButtonColor: '#BB2D3B',
            allowOutsideClick:false,
            //showConfirmButton:false
          }).then((result) =>{
            if (result.isConfirmed) {
              localStorage.removeItem("res");  
              this._ngZone.run(() => {
                this.removeAllScript();
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigateByUrl('/payment', { state: authorizeCcRq,skipLocationChange: true });
              });           
            }  
          });
        }
      });
    }
    */
    console.log('closed callback');
  }

}
