import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { SPAJDTO } from 'src/app/interfaces/dto/spaj-dto';
import { ChargePaymentRq } from 'src/app/interfaces/request/charge-payment-rq';
import { CardTokenRq } from 'src/app/interfaces/request/midtrans/card-token-rq';
import { CardTokenRs } from 'src/app/interfaces/response/midtrans/card-token-rs';
import { ChargePaymentRs } from 'src/app/interfaces/response/charge-payment-rs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';
import { TranslateService } from '@ngx-translate/core';
import { PaymentTrxRs } from 'src/app/interfaces/response/midtrans/payment-trx-rs';
import { BaseRs } from 'src/app/interfaces/response/base-rs';
import { CapturePaymentRq } from 'src/app/interfaces/request/capture-payment-rq';
import { CancelPaymentRq } from 'src/app/interfaces/request/cancel-payment-rq';
import { SaveValidationService } from 'src/app/services/validation/save-validation.service';
import { SaveValidationRq } from 'src/app/interfaces/request/validation/save-validation-rq';
import { ActivatedRoute,Router } from '@angular/router';
import { AuthorizeCcRq } from 'src/app/interfaces/request/authorize-cc/authorize-cc-rq';
import {Component, NgZone} from '@angular/core';
import { Observable } from 'rxjs';

declare function getCardToken(cardData: any, result: any):any;
declare function reqOpen3DS(redirect_url: string, result: any):any;

@Injectable({
  providedIn: 'root'
})
export class PaymentService {

  constructor(private httpClient: HttpClient, private translateSvc: TranslateService, private saveValidationService: SaveValidationService,private router: Router,private _ngZone: NgZone) { }


  getSPAJDetail(spajNum:string){

  }

  createPayToken(spaj: SPAJDTO, creditData: CardTokenRq, authorizeCcRq :AuthorizeCcRq, typeCard: string, element: any) {
    // let script = document.createElement('script');
    // // any script can be loading from any domain
    // script.src = "https://cdnjs.cloudflare.com/ajax/libs/lodash.js/4.3.0/lodash.js"
    // document.head.append(script);
    // script.onload = function () {
    //   // the script generates an auxiliary function
    //   alert('dd'); // the function available
    // };

    const scriptTag = document.createElement('script');
    scriptTag.src = environment.midtrans.scriptUri;
    scriptTag.id = "midtrans-script";
    scriptTag.type = "text/javascript";
    scriptTag.setAttribute('data-client-key', environment.midtrans.clientKey);
    scriptTag.setAttribute('data-environment', environment.midtrans.env);
    document.body.appendChild(scriptTag);
    scriptTag.onload = (script) => {
      console.log(script + ' loaded!');
      getCardToken(creditData, (result: any) => this.chargePayment(result, spaj, authorizeCcRq, typeCard, element));
      document.body.removeChild(scriptTag);
    };
  }

  chargePayment(result: CardTokenRs, spaj: SPAJDTO, authorizeCcRq :AuthorizeCcRq, typeCard: string, element: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + 'bWlkdHJhbnMtdXNlcjpwYW5pblNlY3JldEtleTIwMjEh'
      })
    };

    if (result.status_code === "200") {
      const req: ChargePaymentRq = {
        method: "credit_card",
        tokenId: result.token_id,
        totalAmt: spaj.totalAmt,
        spaj: spaj,
        usrRq: 'microsite',
        bnkChargeCode: typeCard,
        name: element.name,
        firstPremi: element.firstPremi,
        renewalPremi: element.renewalPremi,
        email:element.email,
        flagSharia:element.flagSharia
      }
      /* testing button cannot click outside
      this.translateSvc.get("payment.failedTrx").subscribe(word => {
        Swal.fire({
          icon: 'error',
          text: word + "",
          confirmButtonColor: '#BB2D3B',
          allowOutsideClick:false,

        }).then((result) => {
          // if (result.isConfirmed) {
          //   this.captchaService.setCaptchaStatus(false);
          //   this.authenticationSvc.setUser(this.param);
          //   this.authenticationSvc.setNamesText(this.decryptLinkRs.usertype);
          //   this.authenticationSvc.setSyaFlagText(this.decryptLinkRs.syaflag);
          //   this.sendAuthCCNew();
          //   //Swal.fire('Saved!', '', 'success')
          // } 
        });
      })
      */
      this.httpClient.post<ChargePaymentRs>(environment.uriPayment.chargeTrx, req, httpOptions).subscribe(rs => {
        if (rs.statusCode === "201") {
          reqOpen3DS(rs.redirectUri, (result: any) => this.capturePayment(result,spaj,authorizeCcRq,typeCard));

        } else if (rs.statusCode === "200") {
          this.translateSvc.get("payment.successTrx").subscribe(word => {
            Swal.fire({
              icon: 'success',
              text: word,
              confirmButtonColor: '#0B5ED7'
            });
          })

        } else {
          this.translateSvc.get("payment.failedTrx").subscribe(word => {
            Swal.fire({
              icon: 'error',
              text: word + "" + rs.message,
              confirmButtonColor: '#BB2D3B'
            });
          })
        }
      },(error) => {
        console.log(error);
        if (error.status !== 200) {
          this.translateSvc.get("payment.failedTrx").subscribe(word => {
            Swal.fire({
              icon: 'error',
              text: word + "" + error.error.message,
              confirmButtonColor: '#BB2D3B'
            });
          })
        }
      });
    
    } else {
      this.translateSvc.get("payment.failedTrx").subscribe(word => {
        Swal.fire({
          icon: 'error',
          text: word + "" + result.status_message,
          confirmButtonColor: '#BB2D3B'
        });
      })
    }
  }

  capturePayment(req: PaymentTrxRs, spaj: SPAJDTO, authorizeCcRq :AuthorizeCcRq, typeCard: string) {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + 'bWlkdHJhbnMtdXNlcjpwYW5pblNlY3JldEtleTIwMjEh'
      })
    };
    //console.log('transaction-status :'+req.transaction_status);
    //status : capture
    //status : deny
    if(req.transaction_status === 'capture'){
      const capturePaymentReq: CapturePaymentRq = {
        capture: req,
        spaj: spaj,
        usrRq: 'microsite',
        bnkChargeCode: typeCard 
      };

      this.httpClient.post<BaseRs>(environment.uriPayment.captureTrx, capturePaymentReq, httpOptions).subscribe(rs => {
        if (rs.statusCode === "200") {
          /*
          const save: SaveValidationRq = {
            spajNo: spaj.spajNum
          };
          //post save validation
          this.saveValidationService.saveValidationMicrosite(save).subscribe(
            (responseData) => {
              if(responseData.resulString!==''){
                this.translateSvc.get("payment.successPaymentCapture").subscribe(word => {
                  Swal.fire({
                    icon: 'success',
                    title:'<p><b>Pemberitahuan</b></p>',
                    html: word + '<br/><br/>' + 'Silahkan Tutup Halaman Ini.',
                    confirmButtonColor: '#0B5ED7',
                    allowOutsideClick:false,
                    showConfirmButton:false
                  });
                })
              }
            },
            (error) => {
              console.log(error);
              if (error.status !== 200) {
                this.translateSvc.get("payment.failPaymentCapture").subscribe(word => {
                  Swal.fire({
                    icon: 'error',
                    html: word,
                    confirmButtonColor: '#BB2D3B',
                  });
                })
              }
          });
          */
          //this.translateSvc.get("payment.successPaymentCapture").subscribe(word => {
            Swal.fire({
              icon: 'success',
              title:'<p><b>Pemberitahuan</b></p>',
              html: 'Registrasi Kartu Kredit Online Dan Pendebetan Sudah Berhasil.' + '<br/><br/>' + 'Silahkan Tutup Halaman Ini.',
              confirmButtonColor: '#0B5ED7',
              allowOutsideClick:false,
              showConfirmButton:false
            });
          //})
          // Swal.fire({
          //   icon: 'success',
          //   text: rs.message,
          //   confirmButtonColor: '#0B5ED7',
          //   allowOutsideClick:false,
          //   showConfirmButton:false
          // });
        } else {
          Swal.fire({
            icon: 'error',
            text: rs.message,
            confirmButtonColor: '#BB2D3B',
            //allowOutsideClick:false,
            //showConfirmButton:false
          });
        }
      },(error) => {
        console.log(error);
        if (error.status !== 200) {
          Swal.fire({
            icon: 'error',
            text: error.error.message,
            confirmButtonColor: '#BB2D3B',
            //allowOutsideClick:false,
            //showConfirmButton:false
          });
        }
      });
    }else{
      var msg='';
      if(req.channel_response_code == "05" && req.channel_response_message == "Do not honour"){
        msg='Transaksi DiTolak Oleh Bank !!!';
      }
      const cancelPaymentReq: CancelPaymentRq = {
        cancel: req,
        spaj: spaj,
        usrRq: 'microsite',
        bnkChargeCode: typeCard
      };

      this.httpClient.post<BaseRs>(environment.uriPayment.cancelTrx, cancelPaymentReq, httpOptions).subscribe(rs => {
        if (rs.statusCode === "200") {
          Swal.fire({
            icon: 'success',
            text: rs.message,
            confirmButtonColor: '#0B5ED7',
            //allowOutsideClick:false
          }).then((result) =>{
            if (result.isConfirmed) {
              this._ngZone.run(() => {
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigateByUrl('/payment', { state: authorizeCcRq,skipLocationChange: true });
              });
            }  
          });
        } else {
          if(msg!=""){
            Swal.fire({
              icon: 'error',
              text: msg,
              confirmButtonColor: '#BB2D3B',
              //allowOutsideClick:false
            }).then((result) =>{
              if (result.isConfirmed) {
                this._ngZone.run(() => {
                  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                  this.router.onSameUrlNavigation = 'reload';
                  this.router.navigateByUrl('/payment', { state: authorizeCcRq,skipLocationChange: true });
                });            
              }  
            });
          }else{
            //console.log('messge :'+rs.message);
            Swal.fire({
              icon: 'error',
              text: rs.message,
              confirmButtonColor: '#BB2D3B',
              //allowOutsideClick:false
            }).then((result) =>{
              if (result.isConfirmed) {
                this._ngZone.run(() => {
                  this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                  this.router.onSameUrlNavigation = 'reload';
                  this.router.navigateByUrl('/payment', { state: authorizeCcRq,skipLocationChange: true });
                });              
              }  
            });
          }
        }
      },(error) => {
        console.log(error);
        if (error.status !== 200) {
          Swal.fire({
            icon: 'error',
            text: error.error.message,
            confirmButtonColor: '#BB2D3B',
            //allowOutsideClick:false,
            //showConfirmButton:false
          }).then((result) =>{
            if (result.isConfirmed) {
              this._ngZone.run(() => {
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.onSameUrlNavigation = 'reload';
                this.router.navigateByUrl('/payment', { state: authorizeCcRq,skipLocationChange: true });
              });           
            }  
          });
        }
      });
    }
  }
  
  chargePaymentNew(chargePaymentRq: ChargePaymentRq): Observable<ChargePaymentRs>{
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + 'bWlkdHJhbnMtdXNlcjpwYW5pblNlY3JldEtleTIwMjEh',
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Authorization',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, PATCH'
      })
    };

    return this.httpClient.post<ChargePaymentRs>(
      `${environment.uriPayment.chargeTrx}`,chargePaymentRq,httpOptions
    );
  }

  capturePaymentNew(capturePaymentRq: CapturePaymentRq): Observable<BaseRs> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + 'bWlkdHJhbnMtdXNlcjpwYW5pblNlY3JldEtleTIwMjEh'
      })
    };

    return this.httpClient.post<BaseRs>(
      `${environment.uriPayment.captureTrx}`,capturePaymentRq,httpOptions
    );
  }

  cancelPaymentNew(cancelPaymentRq: CancelPaymentRq): Observable<BaseRs> {
    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
        'Authorization': 'Basic ' + 'bWlkdHJhbnMtdXNlcjpwYW5pblNlY3JldEtleTIwMjEh'
      })
    };

    return this.httpClient.post<BaseRs>(
      `${environment.uriPayment.cancelTrx}`,cancelPaymentRq,httpOptions
    );
  }


}

