<div class="container2">
    <mat-progress-spinner [mode]="'indeterminate'" *ngIf="loader"></mat-progress-spinner>

    <div class="header">
        <button class="btn btn-primary" (click)="downloadPDF()" *ngIf="pdfSrc">
            download
        </button>
    </div>
    <pdf-viewer [src]="pdfSrc" [show-borders]="true" [render-text]="true" [original-size]="false" [fit-to-page]="true"
        style="width: 100%; height: 100%;"></pdf-viewer>
</div>

