import { CdkTextareaAutosize } from '@angular/cdk/text-field';
import { Component, OnInit, ViewChild, NgZone } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { take } from 'rxjs/operators';
import { UpdateWelcomeCallDataRq } from 'src/app/interfaces/request/welcome-call/welcome-caall-rq';
import { WelcomeCallService } from 'src/app/services/welcome-call/welcome-call.service';
import Swal from 'sweetalert2';
import * as moment from 'moment';
import { GetWcallRq } from 'src/app/interfaces/request/welcome-call/get-wcall-rq';
import { ActivatedRoute, Route } from '@angular/router';
import { LoadingService } from 'src/app/services/loading.service';

interface Hours {
  value: string;
  viewValue: string;
  disabled: boolean;
}

@Component({
  selector: 'app-welcome-call',
  templateUrl: './welcome-call.component.html',
  styleUrls: ['./welcome-call.component.scss'],
})
export class WelcomeCallComponent implements OnInit {
  date: string = "";
  time: string = "";
  wcid: string = "";
  clientNote: string = "";
  nomorPolis: string = "";
  callStatus: string = "";
  isEdit: boolean = false;

  maxDate: moment.Moment = moment();
  minDate: moment.Moment = moment().add(1, 'week');
  hours: Hours[] = [];

  onlineOffline: boolean = false;

  wCall: FormGroup = new FormGroup({
    date: new FormControl("", [Validators.required]),
    hour: new FormControl("", [Validators.required]),
    desc: new FormControl("")
  })



  updateWelcomeCallDataRq: UpdateWelcomeCallDataRq = {} as UpdateWelcomeCallDataRq;
  getWcallRq: GetWcallRq = {} as GetWcallRq;
  linkCode: string | null;
  myHolidayDates: moment.Moment[] = [];

  constructor(private _ngZone: NgZone, private wCallSvc: WelcomeCallService, private translateSvc: TranslateService, private route: ActivatedRoute, private loader: LoadingService) {
    this.linkCode = this.route.snapshot.paramMap.get('link');
    this.hours = [];

    this.generateHour();
    this.getDataPolis();

  }

  generateHour() {
    for (let hour = 8; hour < 17; hour++) {
      let disableHour = false;
      const startHour = hour.toString().padStart(2, '0');
      const endHour = (hour + 1).toString().padStart(2, '0');
      this.hours.push({
        value: hour.toString(),
        viewValue: `${startHour}:00 - ${endHour}:00`,
        disabled: disableHour
      });
    }
  }

  myFilter = (d: moment.Moment | null): boolean => {
    const day = moment(d).day();
    const date = moment(d, "YYYY-MM-DD").format("DD MMM YYYY");

    return day > 0 && day < 6 && !this.myHolidayDates.find(x=>x.format("DD MMM YYYY")==date);

  };

  loginForm = new FormGroup({
    start: new FormControl(),
  });

  ngOnInit(): void { }

  @ViewChild('autosize')
  autosize!: CdkTextareaAutosize;
  triggerResize() {
    this._ngZone.onStable.pipe(take(1))
      .subscribe(() => this.autosize.resizeToFitContent(true));
  }

  getDataPolis(): void {
    let word = "";

    this.getWcallRq = {
      linkCode: this.linkCode!
    }
    this.loader.show();

    this.wCallSvc.getWcall(this.getWcallRq).subscribe(
      (rs) => {
        this.loader.hide();

        if (rs.statusCode === "200") {
          if (rs.message !== "SUCCESS") {

            if (rs.message === "Link Expired") {
              let word = "Permintaan Welcoming Call telah melewati masa mempelajari polis (free look period)";
              word += "<br /><br />Hubungi tenaga pemasar atau Contact Center kami di 021 255 66 788.";

              Swal.fire({
                title:'<p>Welcoming Call</p>',
                html: word ,
              });
              return;
            }

            Swal.fire({
              title:'<p>Welcoming Call</p>',
              text: word + "" + rs.message,
              confirmButtonColor: '#0B5ED7'
            });
          }

          const momentObj = moment(rs.datas.submitDate, "YYYY-MM-DD HH:mm:ss");

          const date = momentObj.format("DD MMM YYYY");
          const hour = momentObj.format("HH:mm");

          this.nomorPolis = rs.datas.polNo;
          this.callStatus = rs.datas.status;
          this.wcid = rs.datas.wcId;
          this.maxDate = moment(rs.datas.expiryDate);
          this.minDate = moment().add(1, 'day');

          if(rs.datas.holidays){
            rs.datas.holidays.forEach(el => {
              this.myHolidayDates.push(moment(el, "YYYY-MM-DD"))
            });          
          }
  
          if (rs.datas.status === "Success - Positive Response") {
            word = "Permintaan untuk Welcoming Call sudah terpenuhi. Kami telah menghubungi Anda pada tanggal ";
            word += date + ", " + hour  + ".";
          
            Swal.fire({
              title:'<p>Welcoming Call</p>',
              html: word,
              confirmButtonColor: '#0B5ED7',
              allowOutsideClick: false,
              showConfirmButton: false,
            })
          }
          // JIKA SUDAH SUBMIT by CLIENT
          else if (rs.datas.isSubmit) {
            if (rs.datas.submitBy === "CLIENT") {
              word = "Permintaan untuk Welcoming Call sebelumnya adalah tanggal ";
              word += date + ", " + hour  + ".";
              word += "<br /><br />Apakah Anda ingin melakukan perubahan?";

              Swal.fire({
                title:'<p>Welcoming Call</p>',
                html: word,
                confirmButtonColor: '#0B5ED7',
                allowOutsideClick: true,
                showCancelButton: true,
                showConfirmButton: true,
                backdrop: true,
                customClass: {
                  cancelButton: 'order-1',
                  confirmButton: 'order-2',
                },
              }).then((result) => {
                console.log(result);
                if (result.isConfirmed) {
                  this.isEdit = true;
                } else {
                  this.isEdit = false;
                  let word = "Permintaan untuk Welcoming Call telah terkirim.<br> Kami akan menghubungi Anda pada tanggal ";
                  word += date + ", " + hour + ".";
                  word += "<br /><br />Pastikan Anda menerima panggilan dari nomor resmi Panin Dai-Ichi Life, 021 255 66 788."

                  Swal.fire({
                    title:'<p>Welcoming Call</p>',
                    html: word,
                    confirmButtonColor: '#0B5ED7',
                    allowOutsideClick: false,
                    showConfirmButton: false,
                  });
                }
              })
            } else if (rs.datas.submitBy === "AGENT") {
              word = "Permintaan untuk Welcoming Call telah dikirimkan oleh tenaga pemasar Anda. Kami akan menghubungi Anda pada tanggal ";
              word += date + ", " + hour + ".";
              word += "<br /><br />Pastikan Anda menerima panggilan dari nomor resmi Panin Dai-ichi Life, 021 255 66 788";

              Swal.fire({
                title:'<p>Welcoming Call</p>',
                html: word,
                confirmButtonColor: '#0B5ED7',
                allowOutsideClick: false,
                showConfirmButton: false,
              })
            }
          }
        } else {
          this.translateSvc.get("wcall.failedDataTrx").subscribe(word => {
            Swal.fire({
              title:'<p>Welcoming Call</p>',
              text: word + "" + rs.message,
              confirmButtonColor: '#0B5ED7'
            });
          })
        }
      },
      (error) => {
        this.loader.hide();
        this.translateSvc.get("wcall.failedDataTrx").subscribe(word => {
          Swal.fire({
            title:'<p>Welcoming Call</p>',
            text: word ,
            confirmButtonColor: '#0B5ED7'
          });
        })
        console.log(error);
      });


    this.isEdit = true;
  }

  submitAppointment(): void {
    if(this.wCall.invalid){
      Swal.fire({
        title:'<p>Welcoming Call</p>',
        text: 'Waktu yang dipilih belum lengkap.',
        confirmButtonColor: '#0B5ED7'
      });

      return;
    }

    if (this.getConnection()) {
      const hourString = this.wCall.get('hour')!.value.padStart(2, '0'); // Add leading zero if needed
      const foundIndex = this.hours.findIndex(e => e.value === this.wCall.get('hour')!.value);
      const time = this.hours[foundIndex].viewValue;

      this.updateWelcomeCallDataRq = {
        wcId: this.wcid,
        polNo: this.nomorPolis,
        date: moment(this.wCall.get('date')!.value).format('YYYY-MM-DD'),
        time: `${hourString}:00:00`,
        clientNote: this.wCall.get('desc')!.value
      }

      console.log(this.updateWelcomeCallDataRq);

      this.wCallSvc.updateWelcomeCallData(this.updateWelcomeCallDataRq).subscribe(
        (rs) => {
          if (rs.statusCode === "200") {

            if (rs.message === "Already Submit") {
              this.getDataPolis();
              return;
            }

            if (rs.message === "Already Full") {
              let word = "Permintaan untuk Welcoming Call gagal. Jam yang di pilih telah penuh.";
              word += "<br /><br />Silahkan memasukan jam lainnya";

              this.getListHour(this.updateWelcomeCallDataRq.date);

              Swal.fire({
                title:'<p>Welcoming Call</p>',
                html: word ,
                confirmButtonColor: '#0B5ED7'
              });
              return;

            }

            let word = "Permintaan untuk Welcoming Call telah terkirim.<br> Kami akan menghubungi Anda pada tanggal ";
            word += moment(this.wCall.get('date')!.value).format('DD MMM YYYY');
            word += ", " + moment(time, "HH:mm:ss").format("HH:mm")  +".";
            word += "<br /><br />Pastikan Anda menerima panggilan dari nomor resmi Panin Dai-Ichi Life, 021 255 66 788."

            Swal.fire({
              title:'<p>Welcoming Call</p>',
              html: word,
              confirmButtonColor: '#0B5ED7',
              allowOutsideClick: false,
              showConfirmButton: false,
            });

          } else {
            this.translateSvc.get("wcall.failedDataTrx").subscribe(word => {
              Swal.fire({
                title:'<p>Welcoming Call</p>',
                text: word + "" + rs.message,
                confirmButtonColor: '#0B5ED7'
              });
            })
          }
        },
        (error) => {
          console.log(error);
        });
    } else {
      this.translateSvc.get("connections.fail").subscribe(word => {
        Swal.fire({
          title:'<p>Koneksi Internet Terputus !!!</p>',
          text: word,
          confirmButtonColor: '#0B5ED7',
          allowOutsideClick: false
        }).then(() => {

        });
      })
    }
  }

  EndDateChange(e: any) {

    console.log(this.wCall.controls['date']);

    console.log(e.value);
    let dateStr: string = moment(e.value).format('YYYY-MM-DD')
    console.log(dateStr);
    this.getListHour(dateStr);

  }

  getListHour(dateObj: string) {
    this.wCallSvc.getListHourWelcomeCall(dateObj).subscribe(
      (rs) => {
        if (rs.statusCode === "200") {
          if (rs.message !== "SUCCESS") {
            this.hours = [];

            for (let hour = 8; hour <= 17; hour++) {
              const foundIndex = rs.datas.findIndex(e => e.jam === hour.toString());
              let disableHour = false;
              if (foundIndex !== -1) {
                console.log(rs.datas[foundIndex].counts);
                if (parseInt(rs.datas[foundIndex].counts, 10) < 1) {
                  disableHour = true;
                }
              }
              
              const startHour = hour.toString().padStart(2, '0');
              this.hours.push({
                value: hour.toString(),
                viewValue: startHour + ":00:00",
                disabled: disableHour
              });
            }
          }
        } else {
          this.translateSvc.get("wcall.failedDataTrx").subscribe(word => {
            Swal.fire({
              title:'<p>Welcoming Call</p>',
              text: word + "" + rs.message,
              confirmButtonColor: '#0B5ED7'
            });
          })
        }
      },
      (error) => {
        console.log(error);
      });
  }

  getConnection(): boolean {
    //CHECK KONEKSI
    this.onlineOffline = navigator.onLine;
    window.addEventListener('online', () => { this.onlineOffline = true });
    window.addEventListener('offline', () => { this.onlineOffline = false });
    return this.onlineOffline;
  }
}
