import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AutoDebitComponent } from './components/auto-debit/auto-debit.component';
import { AuthorizeCcComponent } from './components/authorize-cc/authorize-cc.component';
import { NotfoundPageComponent } from './components/notfound-page/notfound-page.component';
import { DocsComponent } from './components/docs/docs.component';
import { DisclaimerComponent } from './components/disclaimer/disclaimer.component';
import { WelcomeCallComponent } from './components/welcome-call/welcome-call.component';
import { RepricingRedirectComponent } from './components/repricing-redirect/repricing-redirect.component';

const routes: Routes = [
  { path: '', redirectTo: 'authorizecc', pathMatch: 'full' },  
  { path: 'payment', component: AutoDebitComponent },  
  { path: 'authorizecc', component: AuthorizeCcComponent },  
  { path: 'docs', component: DocsComponent },  
  { path: 'docs/:id', component: DocsComponent },  
  { path: 'disclaimer', component: DisclaimerComponent },  
  { path: 'wcall/:link', component: WelcomeCallComponent },  
  { path: 'redirect/otlre', component: RepricingRedirectComponent },
  { path: '**', redirectTo: 'authorizecc', pathMatch: 'full' },
  //{ path: '**', component: NotfoundPageComponent },  // Wildcard route for a 404 page
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
