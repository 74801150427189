import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CommonUtillService {

  constructor() { }

  formatString(str: string, ...val: string[]) {
    for (let index = 0; index < val.length; index++) {
      console.log(val[index]);
      str = str.replace(`{${index}}`, encodeURIComponent(val[index]));
    }
    return str;
  }
}
