// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  /*production
  midtrans: {
    scriptUri: 'https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js',
    clientKey: 'VT-client-C5xXnLEcTQG-4iJ2',
    env: 'production',
  },
  */
  midtrans: {
    scriptUri: 'https://api.midtrans.com/v2/assets/js/midtrans-new-3ds.min.js',
    //clientKey: 'SB-Mid-client-5Z9CSbRjWgsoH2nN',vc1uat-01
    clientKey: 'SB-Mid-client-yrCIXQgo2Mp1DSqn',
    env: 'sandbox'
  },
  uriPayment: {
    chargeTrx: '/microsite-pdl/api/v1/midtrans/charge',
    captureTrx: '/microsite-pdl/api/v1/midtrans/capture',
    cancelTrx: '/microsite-pdl/api/v1/midtrans/cancellog',
    getMicrositeData: '/microsite-pdl/api/v1/microsite/getMicrositeData'
  },
  //uriWservice :'https://link-alt.panindai-ichilife.co.id/wservice',
  uriWservice :'https://apis-dev.panindai-ichilife.co.id/goapps/wservice',
  wServiceKey : '2KIyDqLpNT6Pgq7Zz14yzQiS7Lv2l3d4',
  wServiceHmac : 'PEAzCTaYwlpZdRbD3VY55T9GYm325HkL',

  uriGeneralFile: '/general/docs/api/v1/general/file',
  uriGeneralFileData: '/general/docs/api/v1/general/file/data',

  uriUpdateWelcomeCallData: 'welcome-call/api/v1/site/updateWcallData',
  uriGetWelcomeCall: 'welcome-call/api/v1/getWelcomeCall',
  uriGetListHourWelcomeCall: 'welcome-call/api/v1/getListHourWelcomeCall?appointmentDt={0}',

  // uriUpdateWelcomeCallData: 'https://apis-dev.panindai-ichilife.co.id/goapps/wcall/site/updateWcallData',
  // uriGetWelcomeCall: 'https://apis-dev.panindai-ichilife.co.id/goapps/wcall/getWelcomeCall',
  // uriGetListHourWelcomeCall: 'https://apis-dev.panindai-ichilife.co.id/goapps/wcall/getListHourWelcomeCall?appointmentDt={0}',

  // uriGeneralFile: 'http://api-plias-report-business-services-vc1uat-01.vc1.paninlife.com/api/v1/general/file',
  // uriGeneralFileData: 'http://192.168.1.235:8089/api/v1/general/file/data'

  connectHostUrl: 'https://connect-alt.panindai-ichilife.co.id'

};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
