<div NgxLoadingXBlur [show]="load">
    <div class="container border">
        <img src="./assets/img/Panin-Daichi-det.png" alt="Panin-Daichi-Life" width="225" style="margin: 10px;">

        <div class="content">
            <div class="center">
                <h4>Bantahan (Disclaimer)</h4>
            </div>

            <div>
                <h6>Pernyataan Kebijakan Privasi</h6>

                <ol>
                    <li>Pernyataan Kebijakan Privasi ini menetapkan kebijakan dari PT Panin Dai-ichi Life ("Panin
                        Dai-ichiLife" / "Kami") terkait pengumpulan dan penggunaan data informasi pribadi Anda melalui
                        Situs ini.</li>
                    <li>Pernyataan Kebijakan Privasi ini merupakan bagian integral dari syarat penggunaan Situs ini.
                        Dengan menggunakan Situs ini Anda dianggap telah membaca, mengetahui dan memahami Pernyataan
                        Kebijakan Privasi ini.</li>
                    <li>Apabila Anda tidak setuju dengan Pernyataan Kebijakan Privasi ini, Anda dapat keluar dari Situs
                        ini. Kami menyarankan agar Anda sebaiknya secara berkala mengunjungi Situs ini untuk meninjau
                        kembali syarat penggunaan yang dahulu atau yang terkini dimana Anda terikat padanya.</li>
                </ol>
            </div>
            <div>
                <h6>Pengumpulan Informasi</h6>
                <p>
                    Di Panin Dai-ichi Life pengumpulan informasi pribadi Anda Kami lakukan dengan tidak bertentangan
                    pada peraturan perundang-undangan yang berlaku, dalam rangka penyediaan produk & jasa dan untuk
                    mendukung kegiatan usaha. Seluruh informasi yang terkumpul Kami pergunakan sebatas maksud yang
                    tersebut di atas.
                </p>
            </div>
            <div>
                <h6>Penggunaan Informasi Pribadi</h6>
                <ol>
                    <li>Penggunaan informasi pribadi Anda Kami lakukan untuk maksud & tujuan yang sudah disepakati
                        sebelumnya dan/atau dengan tidak bertentangan pada peraturan perundang-undangan yang berlaku.
                    </li>
                    <li>Informasi pribadi Anda sangat Kami rahasiakan kepada pihak lain diluar dari grup atau afiliasi
                        Panin Dai-ichi Life, Kami tidak akan mengungkapkan informasi pribadi Anda kepada pihak ketiga
                        kecuali atas izin Anda atau dalam kondisi khusus, dengan tidak bertentangan pada peraturan
                        perundangundangan yang berlaku.</li>
                    <li>Kami menyimpan catatan atas informasi pribadi Anda sebagaimana yang dibutuhkan untuk memenuhi
                        tujuan penggunaannya dengan memperhatikan peraturan perundang-undangan yang berlaku.</li>
                </ol>
            </div>

            <div>

                <h6>Perlindungan Informasi Pribadi</h6>

                <p>
                    Informasi pribadi Anda Kami lindungi dengan aman sesuai dengan tingkat sensitivitas informasi untuk
                    menjaga penyalahgunaan akses dari pihak-pihak yang tidak berwenang.</p>
            </div>

            <div>

                <h6>Komitmen Kami terhadap Privasi</h6>
                <ol>
                    <li>Kami berkomitmen untuk mengelola informasi pribadi Anda sesuai dengan prosedur yang berlaku
                        dibawah pengawasan individu yang berkompeten menjaga Kebijakan Privasi.</li>
                    <li>Anda dapat memperbaharui keakuratan dan kelengkapan dari informasi pribadi Anda apabila terdapat
                        perubahan dengan terlebih dahulu mengajukan permintaan baik secara lisan maupun tertulis kepada
                        pihak yang berwenang yang ditunjuk oleh Panin Dai-ichi Life.</li>
                </ol>
            </div>

            <div>
                <h6>Konten Situs</h6>
                <ol>
                    <li>Perubahan pada Situs ini dapat Kami lakukan dari waktu ke waktu tanpa pemberitahuansebelumnya.
                    </li>
                    <li>Beberapa konten dari Situs ini telah disiapkan oleh pihak - pihak di luar Panin Dai-ichi Life
                        dimanakonten tersebut berdiri sendiri dan dapat diakses melalui World Wide Web atau Hypertext
                        Link. Panin Dai-ichi Life tidak dapat menjamin atau memastikan keakuratan, kelengkapan atau
                        keotentikan dari konten tersebut.</li>
                    <li>Panin Dai-ichi Life melarang segala merek jasa, merek dagang, ikon dan logo yang terdapat pada
                        Situs ini untuk digunakan tanpa ijin terlebih dahulu dari Kami. Kecuali dengan jelas dinyatakan
                        lain, Hak Cipta dari setiap konten, gambar atau materi audio-visual sepenuhnya dilindungi dan
                        merupakan milik Panin Dai-ichi Life.</li>
                </ol>
            </div>
            <div>
                <h6>Penggunaan Situs</h6>
                <ol>
                    <li>Situs ini bukan riset independen atau nasihat professional atas keuangan. Produk dan jasa yang
                        Kami sediakan untuk Anda, dapat Anda konsultasikan langsung dengan perwakilan Panin Dai-ichi
                        Life yang berwenang.</li>
                    <li>Situs ini didasarkan dengan "apa adanya" (as it is) sehingga Anda setuju bahwa dengan
                        menggunakan Situs ini maka setiap risiko ada di tangan Anda sepenuhnya tanpa adanya jaminan dari
                        Panin Dai-ichi Life atau setiap pejabatnya, direksinya, atau karyawannya, atau orang lain yang
                        berhubungan dengan pembuatan Situs dan kontennya untuk bertanggung jawab atau berkewajiban atas
                        seseorang untuk setiap kerugian langsung ataupun tidak langsung, kerugian pada pihak ketiga,
                        kerugian khusus, kerusakan atau kehilangan yang mungkin akan timbul sehubungan dengan penggunaan
                        Situs oleh Anda. Panin Dai-ichi Life tidak bertanggung jawab atas setiap ketergantungan Anda
                        pada Situs ini atau kontennya yang mengakibatkan kerugian.</li>
                </ol>
            </div>

            <div>
                <h6>Keamanan</h6>

                <p>
                    Anda setuju bahwa internet adalah suatu media yang keamanan dan kerahasiaan sempurna adalah suatu
                    hal yang tidak mungkin pada saat ini. Tanpa membatasi Sanggahan dalam Pernyataan Kebijakan Privasi,
                    Panin Dai-ichi Life tidak bertanggung jawab atas setiap kerugian yang Anda atau orang lain derita
                    sehubungan dengan pelanggaran keamanan atau kerahasiaan tersebut.
                </p>

                <p>
                    Apabila Anda memiliki pertanyaan yang sehubungan dengan Pernyataan Kebijakan Privasi tersebut diatas
                    Anda dapat menghubung customer care kami di:
                </p>

            </div>
        </div>
        <div class="contact">
            <div>CUSTOMER CARE</div>
            <div>Panin Life Center 5th Fl.</div>
            <div>Jl. Letjend. S. Parman Kav. 91</div>
            <div>Jakarta 11420, Indonesia</div>
            <div>Tel: +62 21 255 66 788</div>
            <div>Fax: +62 21 255 66 889</div>
            <div>Email customer@panindai-ichilife.co.id</div>
            <div>Waktu Operasional Senin s/d Jumat 08.30 WIB - 17.30 WIB</div>
        </div>

    </div>
</div>
<ngx-loading-x [show]="load">
</ngx-loading-x>