import { AbstractControl } from "@angular/forms";

export class NumberValidator {
    static isValid(control: AbstractControl): any {
        if (isNaN(control.value)) {
          return {
            'not a number': true,
          };
        }
    
        if (!control.value || control.value == '') {
          return {
            empty: true,
          };
        }
    
        if (control.value % 1 !== 0) {
          return {
            'not a whole number': true,
          };
        }
    
        if (/\s/.test(control.value)) {
          return {
            'contains spaces': true,
          };
        }
    
        return null;
      }
}
