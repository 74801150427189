import { AfterViewChecked, ChangeDetectorRef } from '@angular/core';
import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { LoadingService } from 'src/app/services/loading.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements AfterViewChecked {
  title = 'Microsite';
  loading$ = this.loader.loading$;
  show :boolean= false;

constructor(private translate:TranslateService,public loader: LoadingService,private readonly changeDetectorRef: ChangeDetectorRef){
  translate.setDefaultLang('id');
}

ngAfterViewChecked(): void {
  this.changeDetectorRef.detectChanges();
}

// ngAfterViewChecked() {
//   this.cdRef.detectChanges();
// }

}
