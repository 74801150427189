<div class="jumbotron vertical-center">
    <div class="container border">
        <img src="./assets/img/Panin-Daichi-det.png" alt="Panin-Daichi-Life" width="225" style="margin: 10px;">
        <form (ngSubmit)="chargeAuthorizeCC()" [formGroup]="homeForm">
            <div class="card">
                <div class="card-header">{{ 'home.authorize_cc' | translate }}</div>
                <div class="card-body">
                    <div class="mb-3 row">
                        <label for="staticNoSpaj" class="col-sm-2 col-form-label">{{ 'home.noSpaj' | translate }}</label>
                        <div class="col-sm-10">
                            <input type="number" class="form-control form-control-sm" id="noSpaj" formControlName="noSpaj" value="" readonly>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label for="inputNama" style="text-transform:capitalize !important" class="col-sm-2 col-form-label">Nama {{ decryptLinkRs.usertype }}</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control form-control-sm" id="namePPPH" formControlName="namePPPH" value="" readonly>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label for="inputNik" class="col-sm-2 col-form-label">{{ 'home.nik1' | translate }} {{decryptLinkRs.idtype}} {{ 'home.nik2' | translate }}</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control form-control-sm" id="nik" formControlName="nik" />
                        </div>
                        <!-- <div *ngIf="homeForm.controls['nik'].invalid && (homeForm.controls['nik'].dirty || homeForm.controls['nik'].touched)" class="alert alert-danger">
                            <div *ngIf="homeForm.controls['nik'].errors?.required">
                                nik is required.
                        </div>
                        </div> -->
                    </div>    
                    <div class="mb-3 row">
                        <label for="inputTglLahir" class="col-sm-2 col-form-label">{{ 'home.tglLahir' | translate }}</label>
                        <div class="col-sm-10">
                            <input type="date" class="form-control form-control-sm" id="tglLahir" formControlName="tglLahir" value="" max="2999-12-31">
                        </div>
                    </div>
                    <!-- <div class="mb-3 row">
                        <label for="inputCaptcha" class="col-sm-2 col-form-label">{{ 'home.captcha' | translate }}</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control form-control-sm" id="captcha" value="" formControlName="captcha" readonly>
                            <button class="btn btn-secondary btn-sm" type="button" id="btnRefreshCaptcha" (click)="captcha()" ><b>Refresh</b></button>
                        </div>
                    </div>
                    <div class="mb-3 row">
                        <label for="inputCaptcha" class="col-sm-2 col-form-label">{{ 'home.reCaptcha' | translate }}</label>
                        <div class="col-sm-10">
                            <input type="text" class="form-control form-control-sm" id="recaptcha" value="" formControlName="recaptcha" onpaste="return false;" ondrop="return false;" autocomplete="off">
                        </div>
                    </div> -->
                    <div class="mb-3 row">
                        <ngx-captcha [config]="captchaConfig"></ngx-captcha>
                    </div>    
                    <div class="d-grid">
                        <button type="submit" [disabled]="homeForm.invalid || !captchaStatus" class="btn btn-primary" id="btnLanjut">{{ 'home.btnLanjut' | translate }}</button>
                    </div>
                </div>    
            </div>
        </form>
    </div>
</div>    
<!-- pop up modal old
<div
  class="modal"
  tabindex="-1"
  role="dialog"
  [ngStyle]="{'display':displayStyle}">
  <div class="modal-dialog" role="document">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">GeeksForGeeks</h4>
      </div>
      <div class="modal-body">
        <p>One Stop Solution for all CS problems</p>
  
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" 
                (click)="closePopup()">
          Close
        </button>
      </div>
    </div>
  </div>
</div>
-->