import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { NumberValidator } from 'src/app/validators/number-validator';
import { TranslateService } from '@ngx-translate/core';
import Swal from 'sweetalert2';
import { ActivatedRoute,Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import {MatDialog,MatDialogConfig,MAT_DIALOG_DATA} from '@angular/material/dialog';
import { DecryptLinkService } from 'src/app/services/decryptLink/decrypt-link.service';
import { DecryptLinkRs } from 'src/app/interfaces/response/decryptLink/decrypt-link-rs';
import { AuthorizeCcRq } from 'src/app/interfaces/request/authorize-cc/authorize-cc-rq';
import { AuthorizeCcService } from 'src/app/services/authorize-cc/authorize-cc.service';
import { AuthorizeCcRs } from 'src/app/interfaces/response/authorize-cc/authorize-cc-rs';
import { AuthenticationService } from 'src/app/services/utill/authentication.service';
import {NgxCaptchaService} from '@binssoft/ngx-captcha';
import { bufferToggle } from 'rxjs/operators';
import { ValidationService } from 'src/app/services/validation/validation.service';
import { ValidationRs } from 'src/app/interfaces/response/validation/validation-rs';
import { ValidationRq } from 'src/app/interfaces/request/validation/validation-rq';
import { ValidationDateService } from 'src/app/services/validation/validation-date.service';
import { DecryptLinkRq } from 'src/app/interfaces/request/decryptLink/decrypt-link-rq';

@Component({
  selector: 'app-authorize-cc',
  templateUrl: './authorize-cc.component.html',
  styleUrls: ['./authorize-cc.component.scss']
})
export class AuthorizeCcComponent implements OnInit {
  onlineOffline: boolean = false;
  dt: string ='';
  captchaStatus:boolean = false;
  
  captchaConfig:any = {
    type:1, 
    length:5, 
    cssClass:'custom',
    back: {
     stroke:"#2F9688",
     solid:"#f2efd2"
    } , 
    font:{
      color:"#000000", 
      size:"28px"
    }
  };

  homeForm: FormGroup;
  decryptLinkRs: DecryptLinkRs ={
    spajNo :'',
    nik :'',
    name :'',
    dob:'',
    idtype:'',
    usertype:'',
    syaflag:'',
    createDate:''
  };

  decryptLinkRq: DecryptLinkRq={
    param:''
  };

  validationRq :ValidationRq={
    spajNo:''
  }

  validationRs: ValidationRs={
    resultString:''
  };
  //popUp: boolean;
  noSpaj: string;
  namePPPH:string;
  info1:string;
  param:string;
  //displayStyle = "fg";

  element: any = {
    noSpaj: "",
    namePPPH: "",
    nik: "",
    tglLahir: "",
    captcha: "",
    recaptcha: "",
    ota: ""
  }

  authorizeCcRq: AuthorizeCcRq ={
    spajNo :'',
    name :'',
    nik :'',
    dob :''
  };

  constructor(
    private translateSvc: TranslateService, 
    private router: Router,
    private route: ActivatedRoute,
    private http: HttpClient,
    public dialog: MatDialog,
    private decryptSvc: DecryptLinkService,
    private authenticationSvc: AuthenticationService,
    private captchaService:NgxCaptchaService,
    private validationService:ValidationService,
    private validationDateService:ValidationDateService,
    private authorizeSvc: AuthorizeCcService) {
    //this.popUp = false;
    this.info1='Apakah Anda Ingin lanjut ?';
    this.noSpaj ='';
    this.namePPPH = '';
    this.param = '';
    this.captchaStatus =false;
    //this.displayStyle="none";
    localStorage.removeItem("res");  

    this.homeForm = new FormGroup({
      noSpaj: new FormControl("", [Validators.required, NumberValidator.isValid]),
      namePPPH: new FormControl("", [Validators.required]),
      nik: new FormControl("", [Validators.required]),
      tglLahir: new FormControl("", [Validators.required])
      // captcha: new FormControl("", [Validators.required]),
      // recaptcha: new FormControl("", [Validators.required])
    })

    this.route.params.subscribe((params) => {
      if (params) {
        this.param = params.ota?.trim();
        this.authenticationSvc.setUser(this.param);
        // this.namePPPH = params.namePPPH?.trim();
        // this.homeForm.controls.noSpaj.setValue(this.noSpaj);
        // this.homeForm.controls.namePPPH.setValue(this.namePPPH);
      }
    });
    this.checkConnection();
  }

  ngOnInit(): void {
    this.captchaService.captchStatus.subscribe((status)=>{
      //this.captchaStatus = status;
      if (status == false) {
          //alert("Opps!\nCaptcha mismatch")
          this.translateSvc.get("home.failCaptcha").subscribe(word => {
            Swal.fire({ 
              icon: 'error',
              text: word,
              allowOutsideClick:false,
              confirmButtonColor: '#BB2D3B',
            }).then((result) => {
              /* Read more about isConfirmed, isDenied below */
              if (result.isConfirmed) {
                this.captchaStatus = false;
                //Swal.fire('Saved!', '', 'success')
              } 
            });
          })
          
      } else if (status == true)  {
          //alert("Success!\nYou are right")
          this.captchaStatus = true;
      }
    });
    //this.captcha();
    if(this.getConnection()){
      this.decryptLinkRq.param = this.param;
      this.decryptSvc.getDecryptLink(this.decryptLinkRq).subscribe(
        (responseData) => {
          this.decryptLinkRs = responseData;
          if(this.decryptLinkRs.spajNo!=='' && this.decryptLinkRs.name!==''){
            this.authenticationSvc.setNamesText(this.decryptLinkRs.usertype);
            this.authenticationSvc.setSyaFlagText(this.decryptLinkRs.syaflag);
            //console.log('data :'+this.decryptLinkRs);
            // console.log('spajNo :'+responseData.spajNo);
            // console.log('namaPH :'+responseData.namaPH);
            // this.noSpaj = responseData.spajNo;
            // this.namePPPH = responseData.namaPH;
            // 3111023828 => spajNo
            // 1234123412341234 => nik
            // dob => 11/16/2000
      
            this.homeForm.controls.noSpaj.setValue(this.decryptLinkRs.spajNo);
            this.homeForm.controls.namePPPH.setValue(this.decryptLinkRs.name);
            this.dt = this.decryptLinkRs.createDate;
            
            //for testing date validation
            // get date validation
            /*
            if(this.getConnection()){
              if(this.dt!=null){
                let result = this.getDateNumber(this.dt);
                    if(result > 60){
                      this.translateSvc.get("home.failDate").subscribe(word => {
                        Swal.fire({
                          icon: 'info',
                          title:'Silahkan Tutup Tab !!!',
                          width:1000,
                          html:'<b>'+word+'</b>',
                          confirmButtonColor: '#BB2D3B',
                          allowOutsideClick:false,
                          showConfirmButton:false
                        });
                      })
                    }
              }    
            } else{
                this.translateSvc.get("connections.fail").subscribe(word => {
                  Swal.fire({
                    icon: 'info',
                    title:'<p>Koneksi Internet Terputus !!!</p>',
                    text: word,
                    confirmButtonColor: '#0B5ED7',
                    allowOutsideClick:false
                  });
                })
            }
            */
            // get date validation
            //for testing date validation

            if(this.getConnection()){
              this.validationRq.spajNo=this.homeForm.value.noSpaj;
              this.validationService.getValidationMicrosite(this.validationRq).subscribe((responseData2) => {
                this.validationRs = responseData2;
                if(this.validationRs.resultString.toString() == 'Registrasi dan Pendebetan Anda Sedang Diproses' || this.validationRs.resultString.toString() == 'Registrasi dan Pendebetan Anda Sudah Berhasil'){
                  this.translateSvc.get("home.failValidation").subscribe(word => {
                    Swal.fire({
                      icon: 'info',
                      title:'Silahkan Tutup Tab',
                      width:1000,
                      //html:word +"<br/>"+"1. "+this.validationRs.result,
                      html:this.validationRs.resultString.toString(),
                      confirmButtonColor: '#BB2D3B',
                      allowOutsideClick:false,
                      showConfirmButton:false
                    });
                  })
                }else if(this.validationRs.resultString !== ''){
                  
                  //var input ='Hybrid App,Phone-Gap,Apache Cordova,HTML5,JavaScript,BootStrap,JQuery,CSS3,Android Wear API'
                  var output = this.validationRs.resultString.split(",");
                  var length = output.length;
                  console.log(length);
                  console.log(output[0]);
                  var result="";
                  
                  for (let i = 0; i < length; i++) {
                      //console.log ((i+1)+". "+output[i]);
                      result+=(i+1)+". "+output[i]+'<br/>';
                      result.concat(result);
                  }
                  //console.log(result);
                  this.translateSvc.get("home.failValidation").subscribe(word => {
                    Swal.fire({
                      icon: 'info',
                      title:'Silahkan Tutup Tab',
                      width:1000,
                      //html:word +"<br/>"+"1. "+this.validationRs.result,
                      html:word +"<br/>"+result,
                      confirmButtonColor: '#BB2D3B',
                      allowOutsideClick:false,
                      showConfirmButton:false
                    });
                  })
                }else{
                  //validation date
                  if(this.dt!=null){
                    let result = this.getDateNumber(this.dt);
                    if(result > 60){
                      this.translateSvc.get("home.failDate").subscribe(word => {
                        Swal.fire({
                          icon: 'info',
                          title:'Silahkan Tutup Tab !!!',
                          width:1000,
                          html:'<b>'+word+'</b>',
                          confirmButtonColor: '#BB2D3B',
                          allowOutsideClick:false,
                          showConfirmButton:false
                        });
                      })
                    }
                  }
                }
              },(error2) => {
                console.log(error2);
                if (error2.status !== 200) {
                  //this.auth.logoutUser();
                  this.translateSvc.get("home.failDecrypt").subscribe(word => {
                    Swal.fire({
                      icon: 'error',
                      text: word,
                      confirmButtonColor: '#BB2D3B',
                      allowOutsideClick:false,
                      showConfirmButton:false
                    });
                  })
                }
              });
            } else{
                this.translateSvc.get("connections.fail").subscribe(word => {
                  Swal.fire({
                    icon: 'info',
                    title:'<p>Koneksi Internet Terputus !!!</p>',
                    text: word,
                    confirmButtonColor: '#0B5ED7',
                    allowOutsideClick:false
                  });
                })
            }  
            
          }else{
            this.translateSvc.get("home.failLink").subscribe(word => {
              Swal.fire({
                icon: 'error',
                title:'<p>Silahkan Tutup Tab !!!</p>',
                html: '<b>'+word+'</b>',
                confirmButtonColor: '#BB2D3B',
                allowOutsideClick:false,
                showConfirmButton:false
              });
            })
          }
        },
        (error) => {
          console.log(error);
          if (error.status !== 200) {
            //this.auth.logoutUser();
            this.translateSvc.get("home.failLink").subscribe(word => {
              Swal.fire({
                icon: 'error',
                title:'<p>Silahkan Tutup Tab !!!</p>',
                html: '<b>'+word+'</b>',
                confirmButtonColor: '#BB2D3B',
                allowOutsideClick:false,
                showConfirmButton:false
              });
            })
          }
        });
    } else{
      this.translateSvc.get("connections.fail").subscribe(word => {
        Swal.fire({
          icon: 'info',
          title:'<p>Koneksi Internet Terputus !!!</p>',
          text: word,
          confirmButtonColor: '#0B5ED7',
          allowOutsideClick:false
        });
      })
    }
    
    //console.log('KONEKSI INTERNET :'+this.onlineOffline);
  }

  checkConnection(){
    //CHECK KONEKSI
    this.onlineOffline= navigator.onLine;
    window.addEventListener('online', () => {this.onlineOffline = true});
    window.addEventListener('offline', () => {this.onlineOffline = false});
  }

  getConnection(): boolean{
    //CHECK KONEKSI
    this.onlineOffline= navigator.onLine;
    window.addEventListener('online', () => {this.onlineOffline = true});
    window.addEventListener('offline', () => {this.onlineOffline = false});
    return this.onlineOffline;
  }

  openDialog(info: string) {
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data=info;

    const dialogRef = this.dialog.open(DialogAuthorizeCc,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      console.log(`Dialog result: ${result}`);
    });
  }

//   captcha(){
//     var alpha = new Array('A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W', 'X', 'Y', 'Z', 'a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'w', 'x', 'y', 'z');
//     var i;
//     var code = "";
//     for (i = 0; i < 5; i++) {
//         code = code + alpha[Math.floor(Math.random() * alpha.length)] + "";
//     }
// //   var code = a + ' ' + b + ' ' + ' ' + c + ' ' + d + ' ' + e + ' ' + f + ' ' + g;
//     this.homeForm.controls.captcha.setValue(code);
//   }

  removeSpaces (ss: String) {
    return ss.split(' ').join('');
  }

  validCaptcha(): boolean{
    let isPresent:boolean = false;
    var string1 = this.removeSpaces(String(this.homeForm.get('captcha')?.value));
    var string2 = this.removeSpaces(String(this.homeForm.get('recaptcha')?.value));
    if (string1 == string2) {
        //alert(true);
        isPresent = true;
    }
    else {
        //alert(false);
        isPresent = false;
        
    }
    return isPresent;
  }

  validNumbers(numbers: string): boolean{
    return !isNaN(Number(numbers));
  }

  // openPopup() {
  //   this.displayStyle = "block";
  // }
  // closePopup() {
  //   this.displayStyle = "none";
  // }
  getDateNumber(dates:string): Number{
    //let startDate: string = "2021-04-01";
    let startDate: string = dates;
    let date1: Date = new Date();
    let date2: Date = new Date(startDate);
    let timeInMilisec: number = date1.getTime() - date2.getTime();
    let daysBetweenDates: number = Math.ceil(timeInMilisec / (1000 * 60 * 60 * 24));
    console.log('number days :'+daysBetweenDates);
    return (daysBetweenDates-1);
  }

  fetchUser() {
    this.http
      .get('https://api.github.com/users/thisiszoaib')
      .subscribe((res) => {
        console.log(res);
      });
  }

  sendAuthCC(info: string) {
    const sendAuthCC = {
      spajNo: this.homeForm.value.noSpaj,
      name: this.homeForm.value.namePPPH,
      nik: this.homeForm.value.nik,
      dob: this.homeForm.value.tglLahir,
      param: this.param,
      info: info
    };
    //console.log('sendAuthCC :'+sendAuthCC);
    const dialogConfig = new MatDialogConfig();

    dialogConfig.data=sendAuthCC;

    const dialogRef = this.dialog.open(DialogAuthorizeCc,dialogConfig);
    dialogRef.afterClosed().subscribe(result => {
      //console.log(`Dialog result: ${result}`);
      console.log(`Dialog result: Close Pop Up`);
    });
  }

  clearNikAndDate(){
    this.homeForm.controls.tglLahir.setValue('');
    this.homeForm.controls.nik.setValue('');
  }

  sendAuthCCNew() {
    this.authorizeCcRq.spajNo=this.homeForm.value.noSpaj;
    this.authorizeCcRq.name=this.homeForm.value.namePPPH;
    this.authorizeCcRq.nik=this.homeForm.value.nik;
    this.authorizeCcRq.dob=this.homeForm.value.tglLahir;
    if(this.getConnection()){
      //post data for get microsite data
      this.authorizeSvc.sendAuthorizeCcAPI(this.authorizeCcRq).subscribe(
        (responseData) => {
          console.log('sendAuthorizeCcAPI');
          if(responseData.length > 0){
            console.log('sendAuthorizeCcAPI : available data');
            this.router.navigateByUrl('/payment', { state: this.authorizeCcRq });
          }else{
            console.log('sendAuthorizeCcAPI : not available data');
            this.authorizeSvc.sendAuthorizeCc(this.authorizeCcRq).subscribe(
              (responseDatas) => {
                if(responseDatas.length > 0){
                  console.log('sendAuthorizeCc : available data');
                  this.router.navigateByUrl('/payment', { state: this.authorizeCcRq });
                }else{
                  console.log('sendAuthorizeCc : not available data');
                  this.translateSvc.get("payment.failDataAutoDebitCC").subscribe(word => {
                    Swal.fire({
                      icon: 'error',
                      text: word,
                      confirmButtonColor: '#BB2D3B'
                    }).then((result) =>{
                      if (result.isConfirmed) {
                        //this.param =this.authenticationSvc.getUser();
                        this.router.navigate([
                          'authorizecc',
                          {
                            ota: this.param
                          },
                        ]);
                        this.clearNikAndDate();
                        console.log('BACK TO AUTHORIZE CC');
                      }  
                      
                    });
                  })
                }
              },
              (error) => {
                console.log(error);
                if (error.status !== 200) {
                  //this.auth.logoutUser();
                  this.translateSvc.get("payment.failDataAutoDebitCC").subscribe(word => {
                    Swal.fire({
                      icon: 'error',
                      text: word,
                      confirmButtonColor: '#BB2D3B'
                    }).then(() =>{
                      //this.param =this.authenticationSvc.getUser();
                      this.router.navigate([
                        'authorizecc',
                        {
                          ota: this.param
                        },
                      ]);
                      this.clearNikAndDate();
                      console.log('BACK TO AUTHORIZE CC');
                    });
                  })
                }
            });
          }
          
        },
        (error) => {
          console.log(error);
          if (error.status !== 200) {
            console.log('sendAuthorizeCcAPI : not available data');
            this.authorizeSvc.sendAuthorizeCc(this.authorizeCcRq).subscribe(
              (responseDatas) => {
                if(responseDatas.length > 0){
                  console.log('sendAuthorizeCc : available data');
                  this.router.navigateByUrl('/payment', { state: this.authorizeCcRq });
                }else{
                  console.log('sendAuthorizeCc : not available data');
                  this.translateSvc.get("payment.failDataAutoDebitCC").subscribe(word => {
                    Swal.fire({
                      icon: 'error',
                      text: word,
                      confirmButtonColor: '#BB2D3B'
                    }).then((result) =>{
                      if (result.isConfirmed) {
                        //this.param =this.authenticationSvc.getUser();
                        this.router.navigate([
                          'authorizecc',
                          {
                            ota: this.param
                          },
                        ]);
                        this.clearNikAndDate();
                        console.log('BACK TO AUTHORIZE CC');
                      }  
                      
                    });
                  })
                }
              },
              (error) => {
                console.log(error);
                if (error.status !== 200) {
                  //this.auth.logoutUser();
                  this.translateSvc.get("payment.failDataAutoDebitCC").subscribe(word => {
                    Swal.fire({
                      icon: 'error',
                      text: word,
                      confirmButtonColor: '#BB2D3B'
                    }).then(() =>{
                      //this.param =this.authenticationSvc.getUser();
                      this.router.navigate([
                        'authorizecc',
                        {
                          ota: this.param
                        },
                      ]);
                      this.clearNikAndDate();
                      console.log('BACK TO AUTHORIZE CC');
                    });
                  })
                }
            });
          }
      });
    } else{
      this.translateSvc.get("connections.fail").subscribe(word => {
        Swal.fire({
          icon: 'info',
          title:'<p>Koneksi Internet Terputus !!!</p>',
          text: word,
          confirmButtonColor: '#0B5ED7',
          allowOutsideClick:false
        }).then(() =>{
          //this.param =this.authenticationSvc.getUser();
          this.router.navigate([
            'authorizecc',
            {
              ota: this.param
            },
          ]);
          this.clearNikAndDate();
          console.log('BACK TO AUTHORIZE CC');
        });
      })
    } 
   
  }

  // onShowPopUp(){
  //   this.popUp = !this.popUp;
  // }

  chargeAuthorizeCC(){
      //let regexpDOB = new RegExp('^\d{4}(\/)(((0)[1-9])|((1)[0-2]))(\/)([0-2][1-9]|(3)[0-1])$');
      let regexPass = new RegExp('^[a-zA-Z0-9]+$');
      
      if(this.homeForm.valid && this.captchaStatus == true){
          //alert('ok');
          if(this.decryptLinkRs.idtype.toLowerCase() === 'ktp' ){
            if(String(this.homeForm.get('nik')?.value).length == 16 && regexPass.test(String(this.homeForm.get('nik')?.value))){
              //this.sendAuthCC(this.info1);
              Swal.fire({
                title:'<p>'+this.info1+'</p>',
                width: 600,
                padding: '3em',
                showCancelButton: true,
                confirmButtonColor: '#0B5ED7',
                cancelButtonColor: '#BB2D3B',
                confirmButtonText: 'Lanjut',
                cancelButtonText: 'Batal'
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  this.captchaService.setCaptchaStatus(null);
                  this.authenticationSvc.setUser(this.param);
                  this.authenticationSvc.setNamesText(this.decryptLinkRs.usertype);
                  this.authenticationSvc.setSyaFlagText(this.decryptLinkRs.syaflag);
                  this.sendAuthCCNew();
                  //Swal.fire('Saved!', '', 'success')
                } 
              })
              // this.fetchUser();
              // this.router.navigate(['/payment']);
            }else if(String(this.homeForm.get('nik')?.value).length < 16){
                this.translateSvc.get("home.failNik").subscribe(word => {
                  Swal.fire({
                    icon: 'error',
                    text: word,
                    confirmButtonColor: '#BB2D3B',
                  });
                })
            }else if(String(this.homeForm.get('nik')?.value).length > 16){
              this.translateSvc.get("home.failNik2").subscribe(word => {
                Swal.fire({
                  icon: 'error',
                  text: word,
                  confirmButtonColor: '#BB2D3B',
                });
              })
            }else if(String(this.homeForm.get('nik')?.value).length == 16 && !regexPass.test(String(this.homeForm.get('nik')?.value))){
              this.translateSvc.get("home.failNik3").subscribe(word => {
                Swal.fire({
                  icon: 'error',
                  text: word,
                  confirmButtonColor: '#BB2D3B',
                });
              })
            }
          }else{
            if(regexPass.test(String(this.homeForm.get('nik')?.value))){
              Swal.fire({
                title: '<p>'+this.info1+'</p>',
                showCancelButton: true,
                width: 600,
                padding: '3em',
                confirmButtonColor: '#0B5ED7',
                cancelButtonColor: '#BB2D3B',
                confirmButtonText: 'Lanjut',
                cancelButtonText: 'Batal'
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  this.captchaService.setCaptchaStatus(null);
                  this.authenticationSvc.setUser(this.param);
                  this.authenticationSvc.setNamesText(this.decryptLinkRs.usertype);
                  this.authenticationSvc.setSyaFlagText(this.decryptLinkRs.syaflag);
                  this.sendAuthCCNew();
                  //Swal.fire('Saved!', '', 'success')
                } 
              })
            }else if(!regexPass.test(String(this.homeForm.get('nik')?.value))){
              this.translateSvc.get("home.failNik3").subscribe(word => {
                Swal.fire({
                  icon: 'error',
                  text: word,
                  confirmButtonColor: '#BB2D3B',
                });
              })
            }
            //this.sendAuthCC(this.info1);
          }
          
      }else if(!this.homeForm.get('noSpaj')?.valid){
        this.translateSvc.get("home.failNumberSpaj").subscribe(word => {
              Swal.fire({
                icon: 'error',
                text: word,
                confirmButtonColor: '#BB2D3B',
              });
        })
      }
      else if(this.captchaStatus == false){
        this.translateSvc.get("home.failCaptcha").subscribe(word => {
              Swal.fire({
                icon: 'error',
                text: word,
                confirmButtonColor: '#BB2D3B',
              });
        })
        this.element.recaptcha="";
      }else {
        this.translateSvc.get("home.failForm").subscribe(word => {
          Swal.fire({
            icon: 'error',
            text: word,
            confirmButtonColor: '#BB2D3B',
          });
        })
      }
  }
}

@Component({
  selector: 'dialog-authorize-cc',
  templateUrl: './dialog-authorize-cc.html',
})
export class DialogAuthorizeCc {
  message: string;
  authorizeCcRq: AuthorizeCcRq ={
    spajNo :'',
    name :'',
    nik :'',
    dob :''
  };

  // authorizeCcRs: AuthorizeCcRs ={
  //   spajNo:'',
  //   namaPH:'',
  //   mataUang:'',
  //   regularPremi:'',
  //   regularTopUp:'',
  //   freq:'',
  //   totalPremi:'',
  //   premiPertama:'',
  //   premiLanjutan:''
  // };

  constructor(@Inject(MAT_DIALOG_DATA) public data: any, private authorizeSvc: AuthorizeCcService,private router : Router) {
    this.message = data.info;
    this.authorizeCcRq.spajNo=data.spajNo;
    this.authorizeCcRq.name=data.name;
    this.authorizeCcRq.nik=data.nik;
    this.authorizeCcRq.dob=data.dob;
   }

   onSubmit(){
     //for dynamic send object only 1 hit get available data
      // this.authenticationSvc.setUser(this.authorizeCcRq.param);
      this.router.navigateByUrl('/payment', { state: this.authorizeCcRq });
    
    /* //for send param with object
    this.router.navigate([
      'payment',
      {
        spajNo: this.authorizeCcRq.spajNo,
        namaPH: this.authorizeCcRq.namaPH,
        nik: this.authorizeCcRq.nik,
        dob: this.authorizeCcRq.dob
      },
    ]);
    */
     /*
    this.authorizeSvc.sendAuthorizeCc(this.authorizeCcRq).subscribe(
      (responseData) => {
        this.authorizeCcRs = responseData;
      },
      (error) => {
        console.log(error);
        if (error.status === 401) {
          //this.auth.logoutUser();
        }
      }); 
   */
   }
}