import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {
  param: string = '';
  syaflag: string ='';
  names: string='';
  constructor() {
    this.param = '';
    this.syaflag = '';
    this.names = '';
   }

  setUser(params: string) {
    this.param = params;
    localStorage.setItem('param', JSON.stringify(this.param));
  }

  getUser() :string {
    return this.param = JSON.parse(localStorage.getItem('param') || '{}');
  }

  setSyaFlagText(syaflag: string){
    this.syaflag = syaflag;
    localStorage.setItem('syaflag', JSON.stringify(this.syaflag));
  }

  getSyaFlagText() :string {
    return this.syaflag = JSON.parse(localStorage.getItem('syaflag') || '{}');
  }

  setNamesText(names: string){
    this.names = names;
    localStorage.setItem('names', JSON.stringify(this.names));
  }

  getNamesText() :string {
    return this.names = JSON.parse(localStorage.getItem('names') || '{}');
  }
}
