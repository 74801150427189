<div NgxLoadingXBlur [show]="load">
    <div class="container border">
        <img src="./assets/img/Panin-Daichi-det.png" alt="Panin-Daichi-Life" width="225" style="margin: 10px;">
        <p [hidden]="syaflag === 'Y'"><strong>{{ 'payment.title' | translate }}</strong></p>
        <p [hidden]="syaflag !== 'Y'"><strong>{{ 'payment.titleKontribusi' | translate }}</strong></p>
        <!-- <div *ngIf="isLoggedIn; else loggedOut">
            Welcome back, friend.
        </div>
        
        <ng-template #loggedOut>
            Please friend, login.
        </ng-template> -->
        <form (ngSubmit)="chargePayment()" [formGroup]="paymentForm">
            <div class="card">
                <div class="card-header" [hidden]="syaflag === 'Y'"> {{ 'payment.premiHeader' | translate }}</div>
                <div class="card-header" [hidden]="syaflag !== 'Y'"> {{ 'payment.premiHeaderKontribusi' | translate }}</div>
                <div class="card-body">
                    <div class="mb-3">
                        <label for="spajNo" class="form-label">{{ 'payment.spajNo' | translate }}</label>
                        <input type="number" class="form-control" id="spajNo" formControlName="spajNo" readonly>
                        <input type="hidden" class="form-control" id="email" formControlName="email"  readonly>
                    </div>
                    <div class="mb-3">
                        <label for="policyName" style="text-transform:capitalize !important" class="form-label">Nama {{ names }}</label>
                        <input type="text" class="form-control" id="policyName" formControlName="policyName" readonly>
                    </div>
                    <div class="row mb-3">
                        <div class="col">
                            <label for="currency" class="form-label">{{ 'payment.currency' | translate }}</label>
                            <input type="text" class="form-control" id="currency" formControlName="currency" readonly>
                        </div>
                        <div class="col">
                            <label for="frequency" class="form-label" [hidden]="syaflag === 'Y'">{{ 'payment.frequency' | translate }}</label>
                            <label for="frequency" class="form-label" [hidden]="syaflag !== 'Y'">{{ 'payment.frequencyKontribusi' | translate }}</label>
                            <input type="text" class="form-control" id="frequency" formControlName="frequency" readonly>
                        </div>
                    </div>
                    <div class="mb-3">
                        <label for="premi" class="form-label" [hidden]="syaflag === 'Y'">{{ 'payment.premi' | translate }}</label>
                        <label for="premi" class="form-label" [hidden]="syaflag !== 'Y'">{{ 'payment.premiKontribusi' | translate }}</label>
                        <input type="text" class="form-control" id="premi" formControlName="premi" readonly>
                    </div>
                    <div class="mb-3">
                        <label for="topUp" class="form-label" [hidden]="syaflag === 'Y'">{{ 'payment.topUp' | translate }}</label>
                        <label for="topUp" class="form-label" [hidden]="syaflag !== 'Y'">{{ 'payment.topUpKontribusi' | translate }}</label>
                        <input type="text" class="form-control" id="topUp" formControlName="topUp" readonly>
                    </div>
                    <div class="mb-3">
                        <label for="total" class="form-label" [hidden]="syaflag === 'Y'">{{ 'payment.total' | translate }}</label>
                        <label for="total" class="form-label" [hidden]="syaflag !== 'Y'">{{ 'payment.totalKontribusi' | translate }}</label>
                        <input type="text" class="form-control" id="total" formControlName="total" readonly>
                    </div>
                    <div class="mb-3">
                        <label for="firstMethod" class="form-label" [hidden]="syaflag === 'Y'">{{ 'payment.firstMethod' | translate }}</label>
                        <label for="firstMethod" class="form-label" [hidden]="syaflag !== 'Y'">{{ 'payment.firstMethodKontribusi' | translate }}</label>
                        <input type="text" class="form-control" id="firstMethod" formControlName="firstMethod" readonly>
                    </div>
                    <div class="mb-3">
                        <label for="advanceMethod" class="form-label" [hidden]="syaflag === 'Y'">{{ 'payment.advanceMethod' | translate }}</label>
                        <label for="advanceMethod" class="form-label" [hidden]="syaflag !== 'Y'">{{ 'payment.advanceMethodKontribusi' | translate }}</label>
                        <input type="text" class="form-control" id="advanceMethod" formControlName="advanceMethod" readonly>
                    </div>
                </div>
            </div>
            <div class="card mt-3">
                <div class="card-header">{{ 'payment.ccHeader' | translate }}</div>
                <div class="card-body">
                    <div class="mb-3">
                        <label for="cardNo" class="form-label">{{ 'payment.cardNo' | translate }}</label>
                        <input type="text" class="form-control" id="cardNo" maxlength="16" formControlName="cardNo">
                    </div>
                    <span class="text-danger mb-3"
                        *ngIf="!paymentForm.controls.cardNo.valid  && paymentForm.controls.cardNo.dirty">
                        {{ 'payment.warnCardNo' | translate }}
                    </span>
                    <div class="mb-3">
                        <img id="icon-img" src="../../../assets/icons/cc_logo.png" alt="Logo Panin">
                    </div>
                    <div class="row mb-3">
                        <div class="col-sm-6">
                            <label for="month" class="form-label">{{ 'payment.expDate' | translate }}</label>
                            <div class="row">
                                <div class="col">
                                    <input type="text" class="form-control" id="month" maxlength="2" formControlName="month"
                                        placeholder="{{ 'payment.plcMonth' | translate }}">
                                </div>

                                /
                                <div class="col"> <input type="text" class="form-control" id="year" maxlength="2" formControlName="year"
                                        placeholder="{{ 'payment.plcYear' | translate }}">
                                </div>
                            </div>
                        </div>
                        <div class="col-sm-3">
                            <label for="cvv" class="form-label">{{ 'payment.cvv' | translate }}</label>
                            <div class="input-group">
                                <input type="password" class="form-control" id="cvv" maxlength="3" formControlName="cvv"
                                    placeholder="{{ 'payment.plcCvv' | translate }}">
                                <span class="input-group-text"><i class="bi bi-credit-card"></i></span>
                            </div>
                        </div>
                    </div>
                    <span class="text-danger mb-3"
                        *ngIf="!paymentForm.controls.month.valid  && paymentForm.controls.month.dirty">
                        {{ 'payment.warnMonth' | translate }}
                    </span>
                    <span class="text-danger mb-3"
                        *ngIf="!paymentForm.controls.year.valid  && paymentForm.controls.year.dirty">
                        {{ 'payment.warnYear' | translate }}
                    </span>
                    <span class="text-danger mb-3"
                        *ngIf="!paymentForm.controls.cvv.valid  && paymentForm.controls.cvv.dirty">
                        {{ 'payment.warnCvv' | translate }}
                    </span>
                    <p>
                        {{ 'payment.notes' | translate }}
                    </p>
                </div>
            </div>
            <div class="form-check mb-3 mt-3">
                <label class="form-check-label">
                    <input class="form-check-input" type="checkbox" name="agreement" (change)="toggleEditable($event)"> {{ 'payment.agreement' | translate }}
                    <br/><br/>
                    <p [hidden]="syaflag === 'Y'"> {{ 'payment.agreementv2' | translate }} </p>
                    <p [hidden]="syaflag !== 'Y'"> {{ 'payment.agreementv2Kontribusi' | translate }} </p>
                
                </label>
            </div>
            <div class="clearfix mb-3">
                <button type="submit" class="btn btn-primary float-end me-5" id="konfirmasi" [disabled]="paymentForm.invalid || !contentCheck">{{ 'payment.confirm' | translate }}</button>
                <button type="reset" (click)="cancel()" id="batal" class="btn btn-danger float-end me-5">{{ 'payment.cancel' | translate }}</button>
                <!-- <button type="reset" (click)="test()" class="btn btn-danger float-end me-5">{{ 'payment.test' | translate }}</button> -->
            </div>
        </form>
    </div>
</div>
<ngx-loading-x [show]="load">
</ngx-loading-x>    