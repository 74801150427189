import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { BaseRs } from 'src/app/interfaces/response/base-rs';
import { environment } from 'src/environments/environment';
import { v4 as uuidv4 } from 'uuid';
import { HmacService } from '../utill/hmac.service';
import { UpdateWelcomeCallDataRq } from 'src/app/interfaces/request/welcome-call/welcome-caall-rq';
import { GetWcallRs } from 'src/app/interfaces/response/welcome-call/base-rs';
import { CommonUtillService } from '../utill/common-utill.service';
import { GetWcallCountRs } from 'src/app/interfaces/response/welcome-call/wcall-count-rs';

@Injectable({
  providedIn: 'root'
})
export class WelcomeCallService {
  getListHourWelcomeCall(date: string) {
    const resturl = `${environment.uriGetWelcomeCall}`;
    const xDate = moment().utc().format("ddd, DD MMM YYYY HH:mm:ss") + " GMT";
    const path = "/" + resturl.split("/").slice(3).join("/");
    const uuid = uuidv4();

    return this.http.get<GetWcallCountRs>(
      this.commonSvc.formatString(
        environment.uriGetListHourWelcomeCall,
        date
      ),
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Authorization',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, PATCH',
          'Api-Key': environment.wServiceKey,
          'Authorization': this.hmac.getHMACAuthHeader(xDate, path, uuid),
          'X-Date': xDate,
          'X-Uuid': uuid,
        })
      }
    );
  }
  getWcall(GetWcallRq: any) {
    const resturl = `${environment.uriGetWelcomeCall}`;
    const xDate = moment().utc().format("ddd, DD MMM YYYY HH:mm:ss") + " GMT";
    const path = "/" + resturl.split("/").slice(3).join("/");
    const uuid = uuidv4();
    //get untuk balikin data


    return this.http.post<GetWcallRs>(
      `${environment.uriGetWelcomeCall}`, GetWcallRq,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Authorization',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, PATCH',
          'Api-Key': environment.wServiceKey,
          'Authorization': this.hmac.getHMACAuthHeader(xDate, path, uuid),
          'X-Date': xDate,
          'X-Uuid': uuid,
        })
      }
    );
  }

  constructor(private http: HttpClient, private hmac: HmacService, private commonSvc: CommonUtillService) { }

  updateWelcomeCallData(authorizeCcRq: UpdateWelcomeCallDataRq): Observable<BaseRs> {
    const resturl = `${environment.uriUpdateWelcomeCallData}`;
    const xDate = moment().utc().format("ddd, DD MMM YYYY HH:mm:ss") + " GMT";
    const path = "/" + resturl.split("/").slice(3).join("/");
    const uuid = uuidv4();
    //get untuk balikin data
    return this.http.post<BaseRs>(
      `${environment.uriUpdateWelcomeCallData}`, authorizeCcRq,
      {
        headers: new HttpHeaders({
          'Access-Control-Allow-Origin': '*',
          'Access-Control-Allow-Headers': 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Authorization',
          'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, PATCH',
          'Api-Key': environment.wServiceKey,
          'Authorization': this.hmac.getHMACAuthHeader(xDate, path, uuid),
          'X-Date': xDate,
          'X-Uuid': uuid,
        })
      }
    );
  }

}
