import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { ValidationRs } from 'src/app/interfaces/response/validation/validation-rs';
import { ValidationRq } from 'src/app/interfaces/request/validation/validation-rq';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { HmacService } from '../utill/hmac.service';

@Injectable({
  providedIn: 'root'
})
export class ValidationService {

  constructor( private http: HttpClient, private hmac: HmacService ) { }

  getValidationMicrosite(validationRq: ValidationRq): Observable<ValidationRs> {
    const resturl = `${environment.uriWservice}/getValidationMicrosite`;
    const xDate = moment().utc().format("ddd, DD MMM YYYY HH:mm:ss")  + " GMT";  
    const path = "/" + resturl.split("/").slice(3).join("/");
    const uuid = uuidv4();

    //get untuk balikin data
    return this.http.post<ValidationRs>(
      `${environment.uriWservice}/getValidationMicrosite`,validationRq,
      {
        headers: new HttpHeaders({'Access-Control-Allow-Origin': '*',
                                  'Access-Control-Allow-Headers': 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Authorization',
                                  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, PATCH',
                                  'Api-Key': environment.wServiceKey,
                                  'Authorization': this.hmac.getHMACAuthHeader(xDate, path, uuid),
                                  'X-Date': xDate,
                                  'X-Uuid': uuid,})
      }
    );
  }
}
