import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { ResultStringRs } from 'src/app/interfaces/response/result-string-rs';
import { ListBniPaninRq } from 'src/app/interfaces/request/listBniPanin/list-bni-panin-rq';
import { HmacService } from '../utill/hmac.service';
import * as moment from 'moment';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class ListBniPaninService {

  constructor( private http: HttpClient,  private hmac: HmacService ) { }

  checkListBniPanin(listBniPaninRq: ListBniPaninRq): Observable<ResultStringRs> {
    const resturl = `${environment.uriWservice}/getCheckListCcBniPanin`;
    const xDate = moment().utc().format("ddd, DD MMM YYYY HH:mm:ss")  + " GMT";  
    const path = "/" + resturl.split("/").slice(3).join("/");
    const uuid = uuidv4();

    return this.http.post<ResultStringRs>(
      `${environment.uriWservice}/getCheckListCcBniPanin`,listBniPaninRq,
      {
        headers: new HttpHeaders({'Access-Control-Allow-Origin': '*',
                                  'Access-Control-Allow-Headers': 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Authorization',
                                  'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, PATCH',
                                  'Api-Key': environment.wServiceKey,
                                  'Authorization': this.hmac.getHMACAuthHeader(xDate, path, uuid),
                                  'X-Date': xDate,
                                  'X-Uuid': uuid,})
      }
    );
  }
}
