import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';
import { Observable } from 'rxjs';
import { DecryptLinkRs } from 'src/app/interfaces/response/decryptLink/decrypt-link-rs';
import { DecryptLinkRq } from 'src/app/interfaces/request/decryptLink/decrypt-link-rq';
import * as moment from 'moment';
import { v4 as uuidv4 } from 'uuid';
import { HmacService } from '../utill/hmac.service';

@Injectable({
  providedIn: 'root'
})
export class DecryptLinkService {

  constructor( private http: HttpClient, private hmac: HmacService) { }

  getDecryptLink(param:DecryptLinkRq): Observable<DecryptLinkRs> {
    const resturl = `${environment.uriWservice}/decryptLink`;
    const xDate = moment().utc().format("ddd, DD MMM YYYY HH:mm:ss")  + " GMT";  
    const path = "/" + resturl.split("/").slice(3).join("/");
    const uuid = uuidv4();
    //get untuk balikin data
    return this.http.post<DecryptLinkRs>(
      `${environment.uriWservice}/decryptLink`,param,
      {
        headers: new HttpHeaders({'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers': 'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Authorization',
        'Access-Control-Allow-Methods': 'GET, POST, OPTIONS, PUT, DELETE, PATCH',
        'Api-Key': environment.wServiceKey,
        'Authorization': this.hmac.getHMACAuthHeader(xDate, path, uuid),
        'X-Date': xDate,
        'X-Uuid': uuid,})
      }
    );
  }

}
