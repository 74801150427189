<!doctype html>
<html lang="en">

<head>
  <!-- Required meta tags -->
  <meta charset="utf-8">
  <title>PaninDai-ichiLife Microsite</title>
  <meta name="viewport" content="width=device-width, initial-scale=1">
</head>

<body>
  <router-outlet></router-outlet>
  <mat-progress-spinner [mode]="'indeterminate'" *ngIf="loading$ | async"></mat-progress-spinner>
</body>

</html>