import { Injectable } from '@angular/core';
import * as cryptoJs from 'crypto-js';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class HmacService {
  hmacUser = "gopassport-api-user";
  hmacSecret = environment.wServiceHmac;
  hmacAlgorithm = "hmac-sha256";

  constructor() { }
  
  getHMACAuthHeader(date: string, path: string, uuid: string) {

    const signingString = `x-date: ${date}\nPOST ${path} HTTP/1.1\nx-uuid: ${uuid}`; 
    const hmacSha256 = cryptoJs.HmacSHA256(signingString, this.hmacSecret);
    const hmacSignature = cryptoJs.enc.Base64.stringify(hmacSha256);

    return `hmac username="${this.hmacUser}", algorithm="${this.hmacAlgorithm}", headers="x-date request-line x-uuid", signature="${hmacSignature}"`;
  }

}
