import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-repricing-redirect',
  templateUrl: './repricing-redirect.component.html',
  styleUrls: ['./repricing-redirect.component.scss']
})
export class RepricingRedirectComponent implements OnInit {
  constructor(private route: ActivatedRoute, private router: Router) { 
    const queryParams = this.route.snapshot.queryParams;
    let queryParamsString: string = '';
    if (queryParams) {
      queryParamsString = '?';
      const paramKeys: string[] = Object.keys(queryParams);
      paramKeys.forEach((key) => {
        const value = queryParams[key];
        queryParamsString += key + '=' + value
      });
    }

    const path: string = '/otlre'
    window.location.href = environment.connectHostUrl + path + queryParamsString;

  }

  ngOnInit(): void { }

}
