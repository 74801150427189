import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ActivatedRoute } from '@angular/router';
import { GerneralDataRs } from 'src/app/interfaces/response/generalData/general-data-rs';
import { environment } from 'src/environments/environment';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-docs',
  templateUrl: './docs.component.html',
  styleUrls: ['./docs.component.scss'],
})
export class DocsComponent implements OnInit {
  id: string | null = '0';
  pdfSrc: string = '';
  loader = false;

  fileName = '';

  constructor(
    private route: ActivatedRoute,
    private http: HttpClient,
    public sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    this.id = this.route.snapshot.paramMap.get('id');

    this.getData(this.id).subscribe(
      (data) => {
        console.log('data->', data);
        this.loader = true;

        if (data.statusCode == '200') {
          const Rq = {
            from: data.fileLocation,
            to: data.fileLocation,
            fileName: data.fileName,
          };
          this.http
            .post(environment.uriGeneralFile, Rq, {
              reportProgress: true,
              responseType: 'arraybuffer',
              headers: new HttpHeaders({
                'Access-Control-Allow-Origin': '*',
                'Access-Control-Allow-Headers':
                  'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Authorization',
                'Access-Control-Allow-Methods':
                  'GET, POST, OPTIONS, PUT, DELETE, PATCH',
              }),
            })
            .subscribe(
              (pdf) => {
                if (pdf.byteLength > 0) {
                  this.loader = true;
                  console.log(pdf);
                  const blob = new Blob([pdf], { type: 'application/pdf' });
                  var fileURL = URL.createObjectURL(blob);

                  this.pdfSrc = fileURL;
                } else {
                  Swal.fire({
                    icon: 'error',
                    text: 'DATA TIDAK DITEMUKAN',
                    confirmButtonColor: '#BB2D3B',
                  });
                }
              },
              (err) => {
                this.loader = false;

                console.log('err->', err);
              },
              () => {
                this.loader = false;
              }
            );
        } else {
          this.loader = false;

          Swal.fire({
            icon: 'error',
            text: 'DATA TIDAK DITEMUKAN',
            confirmButtonColor: '#BB2D3B',
          });
        }
      },
      (err) => {
        this.loader = false;

        console.log('err->', err);
      }
    );
  }

  getData(fileCode: any) {
    const Rq = {
      fileCode: fileCode,
    };

    return this.http.post<GerneralDataRs>(environment.uriGeneralFileData, Rq, {
      headers: new HttpHeaders({
        'Access-Control-Allow-Origin': '*',
        'Access-Control-Allow-Headers':
          'X-API-KEY, Origin, X-Requested-With, Content-Type, Accept, Access-Control-Request-Method, Authorization',
        'Access-Control-Allow-Methods':
          'GET, POST, OPTIONS, PUT, DELETE, PATCH',
      }),
    });
  }

  downloadPDF() {
    const element = document.createElement('a');
    element.href = this.pdfSrc;
    element.download = this.fileName;
    document.body.appendChild(element);
    element.click();
  }
}
